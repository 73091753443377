import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 0;
  .logo2 {
    width: 200%;
    height: auto;
    margin: -90px; /* Remove margem geral */
    margin-bottom: -10px; /* Pequena margem inferior */
    @media (max-width: 908px) {
      width: 100%;
    }
  }
  .button {
    margin-left: 15px;
    margin-top: 0px;
    width: 150px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
      font-family: 'MyFont';

      &:checked {
        background-color: #0197f6;
        font-family: 'MyFont';
      }
    }
    @media (max-width: 1086px) {
      font-size: 20px;
    }
    @media (max-width: 594px) {
      font-size: 30px;
    }
  }
`;

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1px;
  @media (max-width: 768px) {
    width: 100%;
  }
  h1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; /* Ajuste da margem inferior */
    font-family: 'MyFont';
    margin-top: -120px;
    @media (max-width: 908px) {
      font-size: 20px;
    }
    @media (max-width: 594px) {
      font-size: 15px;
    }
    @media (max-width: 492px) {
      font-size: 20px;
    }
  }
  .text-field {
    margin-top: 10px;
    width: 70%;
    font-family: 'MyFont';

    & label {
      font-size: 16px; /* Tamanho base */
      font-family: 'MyFont';
      border-color: #ae1100;
    }

    & label.Mui-focused {
      color: #ae1100;
      font-size: 18px; /* Tamanho no foco */
      @media (max-width: 908px) {
        font-size: 14px;
      }
      @media (max-width: 594px) {
        font-size: 12px;
      }
    }

    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }

      input {
        font-size: 16px; /* Tamanho base para texto digitado */

        @media (max-width: 908px) {
          font-size: 14px; /* Ajuste para telas menores */
        }

        @media (max-width: 594px) {
          font-size: 12px; /* Ajuste adicional para telas muito pequenas */
        }
      }
    }

    @media (max-width: 908px) {
      & label {
        font-size: 14px;
      }
    }

    @media (max-width: 594px) {
      & label {
        font-size: 12px;
      }
    }

    @media (max-width: 908px) {
      font-size: 20px;
    }
    @media (max-width: 594px) {
      font-size: 15px;
    }
  }

  button:not(.button-eye) {
    margin-top: 10px;
    width: 70%;
    color: white;
    text-transform: none;
    font-weight: bold;
    font-family: 'MyFont';
    background-color: #ae1100;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
    @media (max-width: 1024px) {
      height: 10%;
    }
    @media (max-width: 1440px) {
      height: 6%;
    }
    @media (max-width: 2560px) {
      height: 6%;
    }
  }
  .button-eye svg {
    width: 24px; /* Tamanho padrão */
    height: 24px; /* Tamanho padrão */

    @media (max-width: 1024px) {
      width: 20px; /* Reduzir tamanho em telas menores */
      height: 20px;
    }

    @media (max-width: 768px) {
      width: 18px; /* Reduzir ainda mais para dispositivos pequenos */
      height: 18px;
    }

    @media (max-width: 480px) {
      width: 16px; /* Tamanho mínimo para dispositivos muito pequenos */
      height: 16px;
    }
  }

  select {
    width: 250px;
    height: 40px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin-top: 5px;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 35%;
  }
  .input-label {
    font-family: 'MyFont';
  }
  .label {
    font-family: 'MyFont';
  }

  .enviar {
    margin-top: 10%;
    color: #ae1100;
    font-family: 'MyFont';
  }
  .logo {
    width: 80%;
    max-width: 490px; /* Limite a largura máxima */
    height: auto;
    margin: -90px; /* Remove margem geral */
    margin-bottom: 10px; /* Pequena margem inferior */
    @media (max-width: 1208px) {
      margin-bottom: 9%;
    }
    @media (max-width: 906px) {
      margin-bottom: 5%;
    }
    @media (max-width: 446px) {
      margin-bottom: 10%;
    }
  }
  .button {
    margin-left: 15px;
    margin-top: 0px;
    width: 150px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
      font-family: 'MyFont';

      &:checked {
        background-color: #0197f6;
        font-family: 'MyFont';
      }
    }
    @media (max-width: 908px) {
      font-size: 20px;
    }
    @media (max-width: 594px) {
      font-size: 15px;
    }
  }
  .button-custom {
    background-color: #ae1100;
    color: #fff;
    border: none;
    font-family: 'MyFont';
    text-transform: none;
  }
  .button-custom:hover {
    background-color: #ae1100;
    text-transform: none;
  }
  .button-custom:disabled {
    background-color: #ae1100; /* Cor cinza para desabilitado */
    color: #ae1100;
    font-family: 'MyFont';
    text-transform: none;
  }
  @media (max-width: 806px) {
    margin-top: 5%;
  }
`;
