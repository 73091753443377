import styled from 'styled-components';

import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 95vw; /* Ajuste a largura para adaptação em telas menores */
  max-width: 750px; /* Limita a largura máxima em telas grandes */
  height: auto; /* Permite que a altura seja ajustada ao conteúdo */
  max-height: 85vh; /* Limita a altura máxima */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  overflow-y: auto; /* Permite rolagem apenas vertical */
  overflow-x: hidden; /* Impede rolagem horizontal */
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Estilos para dispositivos móveis */
  @media (max-width: 768px) {
    width: 90vw; /* Reduz a largura em tablets */
    max-width: 90%; /* Mais flexível em telas menores */
    height: auto;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 95vw; /* Quase ocupa toda a largura da tela */
    padding: 0px;
    border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
  }
`;

export const Overlay = styled.div`
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  font-family: 'MyFont';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;
export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont', sans-serif !important;
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  margin: -5% auto 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  position: relative;

  h1 {
    margin: -32px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 820px) {
      width: 27vw; /* Reduz a largura em tablets */
      max-width: 90%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 30px;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 40vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 20px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 40vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 20px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }

  h4 {
    margin: 15px 1px;
    font-size: 18px;
    margin-bottom: 2%;
    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 480px) {
      font-size: 15px;
    }
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin: 5px;
    margin-left: 15px;
    font-family: 'MyFont';
  }

  button {
    margin-top: 10px;
    width: 150px;
    color: white;
    margin: 1%;
    text-transform: none;
    font-weight: bold;
    font-family: 'MyFont';
    background-color: #ae1100;
    margin-bottom: 5%;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
    }
  }

  input {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0px 10px;
    border-radius: 4px;
    font-family: 'MyFont';
    margin: 2px 50px -5px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }
  }

  select {
    width: 100%; /* Modificado para ocupar toda a largura disponível */
    margin-top: 0px;
    font-size: 15px;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ea1100;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    width: 30vh;
  }

  .text-field {
    font-size: 15px;
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
    @media (max-width: 768px) {
      width: 80vw; /* Reduz a largura em tablets */
      max-width: 120%; /* Mais flexível em telas menores */
      height: auto;
      padding: 0px;
    }

    @media (max-width: 480px) {
      width: 70vw; /* Quase ocupa toda a largura da tela */
      padding: 0px;
      margin-bottom: 15%;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 90px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
    /* Estilos para dispositivos móveis */
    @media (max-width: 768px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      margin-top: 10%;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 80vw; /* Quase ocupa toda a largura da tela */
      border-radius: 10px;
      margin-top: 20%; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 85vw; /* Quase ocupa toda a largura da tela */
      padding: 20px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 55%;
    background-color: white;
  }
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 15px;
    font-family: 'MyFont';
    font-size: 15px; /* Adicionado tamanho da fonte */
  }
`;
