import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import axios from '../../../services/axios';
import Loading from '../../../components/Loading';
import { Container } from './styled';
import checkQuizAnswers from '../../../components/ResultsAnalysis';
import SubmoduleEdit from '../Alert';

export default function Quizz({ quizData }) {
  const { id: submoduleId } = useParams();
  const [Avaliation, setAvaliation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userId = useSelector((state) => state.auth.user.id);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [courseId, setCourseId] = useState(0);
  const [minScore, setScore] = useState(0);
  const [isLastSubmodule, setIsLastSubmodule] = useState(false);
  const [submodulesCourses, setSubmodules] = useState([]);
  const [passedSubmodules, setPassedSubmodules] = useState([]);

  const [openModal, setOpenModal] = useState(false); // Estado para controlar a abertura do modal

  function parseQuestions(questions) {
    try {
      return JSON.parse(JSON.parse(questions));
    } catch (e1) {
      try {
        return JSON.parse(questions);
      } catch (e2) {
        return [];
      }
    }
  }

  useEffect(() => {
    if (!submoduleId) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      setIsLoading(true);

      try {
        const submoduleResponse = await axios.get(`/submodule/${submoduleId}`);
        const currentCourseId = submoduleResponse.data.courseId;
        setCourseId(currentCourseId);

        const quizResponse = await axios.get(`/quiz`);
        const Quizzes = filter(quizResponse.data, (quiz) => quiz.submodule_id === Number(submoduleId));
        if (Quizzes.length > 0) {
          setAvaliation(Quizzes);
          setScore(Quizzes[0].maxScore);
        } else {
          setAvaliation([]);
          setScore(0);
        }

        const submodulesResponse = await axios.get(`/submodule`);
        const submodules = submodulesResponse.data;

        const filteredSubmodules = submodules.filter((submodule) => submodule.courseId === currentCourseId);
        setSubmodules(filteredSubmodules);
        const maxSubmoduleId = Math.max(...filteredSubmodules.map((submodule) => submodule.id));

        const submoduleIds = filteredSubmodules.map((submodule) => submodule.id);

        const userSubmoduleResponse = await axios.get(`/usersubmodule`);
        const userSubmodules = userSubmoduleResponse.data.filter(
          (userSubmodule) =>
            userSubmodule.userId === userId &&
            userSubmodule.passedQuiz === true &&
            submoduleIds.includes(userSubmodule.submodule_id),
        );
        setPassedSubmodules(userSubmodules);
        setIsLastSubmodule(!!(Number(submoduleId) === maxSubmoduleId));
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }

    getData();
  }, [submoduleId]);

  const currentQuiz = quizData || Avaliation[0]; // Usamos o prop quizData aqui
  const questions = parseQuestions(currentQuiz?.questions || '[]');

  const handleSendAnswers = async () => {
    await checkQuizAnswers(
      selectedAnswers,
      submoduleId,
      questions,
      minScore,
      isLastSubmodule,
      userId,
      courseId,
      submodulesCourses,
      passedSubmodules,
    );
    setOpenModal(true); // Abre o modal quando o quiz for enviado
  };

  return (
    <Container>
      <div>
        <Loading isLoading={isLoading} />
        {questions.slice(currentQuestionIndex, currentQuestionIndex + 1).map((question) => (
          <div key={question.id} className="teste" style={{ paddingTop: '5rem' }}>
            <p className="question">{`${currentQuestionIndex + 1}. ${question.questionText}`}</p>
            {question.answers.map((answer) => (
              <div key={answer.id}>
                <input
                  className="radio"
                  type="radio"
                  id={`answer-${answer.id}`}
                  name={`question-${question.id}`}
                  value={answer.id}
                  checked={selectedAnswers[`question-${question.id}`]?.id === answer.id}
                  onChange={(e) =>
                    setSelectedAnswers({
                      ...selectedAnswers,
                      [e.target.name]: answer,
                    })
                  }
                />
                <label htmlFor={`answer-${answer.id}`}>{answer.text}</label>
              </div>
            ))}
          </div>
        ))}
        <div className="button-container">
          {currentQuestionIndex > 0 && (
            <button
              className="button-quiz"
              type="button"
              onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
            >
              Voltar
            </button>
          )}
          {currentQuestionIndex < questions.length - 1 ? (
            <button
              style={{ backgroundColor: '#ae1100' }}
              className="button-quiz"
              type="button"
              onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
            >
              Próximo
            </button>
          ) : (
            <button className="button-quiz" type="button" onClick={() => handleSendAnswers()}>
              {isLastSubmodule ? 'Finalizar Curso' : 'Enviar resposta'}
            </button>
          )}
        </div>
        <p>{`Perguntas respondidas: ${Object.keys(selectedAnswers).length} / ${questions.length}`}</p>
      </div>

      {/* Modal */}
      <SubmoduleEdit openModal={openModal} setOpenModal={setOpenModal} />
    </Container>
  );
}

Quizz.propTypes = {
  quizData: PropTypes.shape({
    questions: PropTypes.string,
    maxScore: PropTypes.number,
  }).isRequired, // Se quizData for obrigatório
};
