import styled from 'styled-components';
import Card from '@mui/material/Card';
import breakpoints from '../../styles/breakpoints';

export const CollaboratorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5%;
  flex-wrap: wrap;
  overflow-y: auto;
  margin-top: 5%;

  .link {
    text-decoration: none;
    color: inherit;
  }

  .h1 {
    margin-bottom: 5%;
    font-size: 20px; /* 1.6 vezes o tamanho da fonte base */
    font-weight: 900; /* Torna o texto bem grosso */
    color: #000;
  }

  span {
    font-weight: 600;
    color: #000;
    object-fit: contain;
    min-height: 50px;
    margin: 5px 0px;
    align-items: center;

    @media (min-width: 1440px) {
      font-size: 1.2rem; /* Ajuste do tamanho da fonte em telas grandes */
    }

    @media (max-width: 768px) {
      font-size: 0.9rem; /* Ajuste do tamanho da fonte em telas pequenas */
    }
  }

  svg {
    width: 25vw;
    height: 80px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    min-height: 50px;

    @media (min-width: 1440px) {
      height: 120px;
    }

    @media (max-width: 768px) {
      height: 60px; /* Ajuste o tamanho do SVG em telas pequenas */
    }
  }

  @media (min-width: 1024px) {
    grid-gap: 80px; /* Aumentar o espaçamento em telas maiores */
  }

  @media (max-width: 768px) {
    flex-direction: column;
    grid-gap: 20px; /* Menor espaçamento para telas pequenas */
  }

  .p {
    margin-top: 20%;
  }
`;

export const StyledCard = styled(Card)`
  width: 410px; /* Diminuir a largura do card */
  height: auto; /* Definir uma altura fixa para o card */
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuir o espaço verticalmente */
  aspect-ratio: 3 / 4;

  @media ${breakpoints.md} {
    height: 100%;
    height: auto; /* Ajuste a largura em dispositivos médios */
  }

  @media ${breakpoints.mg} {
    height: 80%;
    width: 70%; /* Ajuste em dispositivos maiores */
  }

  @media (max-width: 1865px) {
    width: 70%; /* Ocupa 100% da largura em telas pequenas */
    height: auto; /* Ajusta a altura conforme o conteúdo */
    margin-top: 20%;
  }
  @media (max-width: 1434px) {
    width: 80%; /* Ocupa 100% da largura em telas pequenas */
    height: auto; /* Ajusta a altura conforme o conteúdo */
    margin-top: 20%;
  }

  @media (max-width: 768px) {
    width: 50%; /* Ocupa 100% da largura em telas pequenas */
    height: auto; /* Ajusta a altura conforme o conteúdo */
    margin-top: 20%;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px #ae1100;
  }

  .MuiCardMedia-root {
    height: 150px;
  }

  img {
    width: 190%; /* A imagem ocupa 100% da largura do container */
    height: auto; /* Ajusta a altura proporcionalmente */
    margin-bottom: 12%;
    z-index: 0;
    object-fit: cover; /* Mantém as proporções sem distorcer */
    border-radius: 8px;
    @media (max-width: 1888px) {
      width: 200%;
      height: 50%; /* Ajusta a altura conforme o conteúdo */
    }
    @media (max-width: 1864px) {
      width: 190%;
      height: 40%; /* Ajusta a altura conforme o conteúdo */
    }
    @media (max-width: 1411px) {
      width: 190%;
      height: auto; /* Ajusta a altura conforme o conteúdo */
    }
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin-top: -10%;
    margin-bottom: 10px; /* Ajuste a margem inferior para separar do botão */
    overflow-y: auto; /* Adicionar rolagem vertical */
    max-height: 300px; /* Definir altura máxima para o conteúdo */

    /* Ocultar barras de rolagem no navegador Webkit */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    /* Ocultar barras de rolagem no navegador Firefox */
    scrollbar-width: none;
  }

  .MuiTypography-root {
    margin-top: 8px;
    font-weight: bold;
    text-align: left;
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 16px; /* Ajuste o tamanho da fonte para dispositivos pequenos */
    }
  }

  .MuiCardActions-root {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    margin-top: auto; /* Empurra o botão para o final do card */
  }

  button {
    display: inline-block;
    width: 100%; /* Ajusta a largura do botão para preencher o card */
    padding: 10px;
    font-size: 13px;
    color: white;
    margin-top: -8%;
    background-color: #ae1100;
    border: none;
    border-radius: 4px;
    text-align: center;
    font-family: 'MyFont';
    font-weight: bold;
    text-transform: none;
    line-height: 20px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: #d43210; /* Cor de fundo ao passar o mouse */
    }

    @media (max-width: 768px) {
      font-size: 12px; /* Ajuste o tamanho da fonte em telas pequenas */
    }
  }

  .h1 {
    margin-bottom: 5%;
    font-size: 15px;
    font-weight: 900;
    color: #000;
    border-bottom: 1px solid #000; /* Linha abaixo do título */
    padding-bottom: 8px; /* Espaço entre o título e a linha */
    @media (max-width: 1621px) {
      font-size: 15px;
    }
    @media (max-width: 1506px) {
      font-size: 14px;
    }
    @media (max-width: 1522px) {
      font-size: 13px;
    }
    @media (max-width: 1418px) {
      font-size: 12px;
    }
    @media (max-width: 1400px) {
      font-size: 11px;
    }
    @media (max-width: 1339px) {
      font-size: 10px;
    }
  }
  h2 {
    margin-top: 10px; /* Ajuste da margem superior */
    margin-bottom: 10px; /* Ajuste da margem inferior */
    font-size: 15px;
    font-weight: 500;
    color: #000;
    border-bottom: 1px solid #000; /* Linha abaixo da descrição */
    padding-bottom: 8px; /* Espaço entre o título e a linha */
  }
`;
