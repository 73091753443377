import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';
import breakpoints from '../../styles/breakpoints';

export const Container = styled.section`
  overflow: hidden;
  overflow-y: auto;
  transition: margin-left 0.5s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5%;
  height: 100vh;
  z-index: 1;
  padding-left: 180px;

  @media (max-width: 1126px) {
    padding-left: 0px;
  }

  @media (max-width: 1024px) {
    padding-left: 0px;
  }
  background: #fff;

  .content {
    margin-top: 5%;
  }
`;

export const CollaboratorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;

  @media ${breakpoints.lg} {
    height: 100%;
    width: 150%;
  }

  .img {
    position: absolute;
    top: 2%;
    width: 100%;
    height: 40vh;

    @media (max-width: 1255px) {
      margin-top: 2%;
      height: 35vh;
    }
    @media (max-width: 1027px) {
      margin-top: 3%;
      height: 20vh;
    }

    @media (max-width: 801px) {
      height: 25vh;
    }
    @media (max-width: 768px) {
      height: 20vh;
    }
    @media (max-width: 631px) {
      height: 20vh;
    }
    @media (max-width: 631px) {
      margin-top: 5%;
      height: 15vh;
    }
    @media (max-width: 467px) {
      margin-top: 9%;
      height: 10vh;
    }
    @media (max-width: 395px) {
      margin-top: 8%;
      height: 10vh;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 88%;
    margin-bottom: 3%;
    padding: 2%;
    margin-top: 15%;
    gap: 10px;
    text-align: justify;

    @media (max-width: 1582px) {
      margin-top: 20%;
    }

    @media (max-width: 1027px) {
      flex-direction: column; /* Muda para coluna em telas menores */
      align-items: center;
    }

    @media (max-width: 631px) {
      width: 100%; /* Ajusta a largura para telas muito pequenas */
      margin-top: 20%;
    }
    @media (max-width: 1502px) {
      margin-bottom: -5%;
    }
    @media (max-width: 1272px) {
      margin-bottom: -5%;
    }
    @media (max-width: 1582px) {
      margin-bottom: -1%;
    }
    @media (max-width: 1021px) {
      margin-bottom: 5%;
    }
    @media (max-width: 1359px) {
      margin-bottom: -2%;
    }
    @media (max-width: 1101px) {
      margin-bottom: -3%;
    }
  }

  .course-info {
    max-width: 60%;
    padding-right: 90px;
    text-align: justify;
    margin-bottom: 10px;
    @media (max-width: 1095px) {
      font-size: 15px; /* Reduz tamanho da fonte em telas menores */
    }

    @media (max-width: 1087px) {
      font-size: 15px; /* Reduz tamanho da fonte em telas menores */
    }

    @media (max-width: 1027px) {
      max-width: 100%; /* Ajusta a largura para ocupar o espaço total */
      padding-right: 0;
      margin-bottom: 5%;
      text-align: justify;
      font-size: 20px;
    }
    @media (max-width: 564px) {
      font-size: 15px;
      padding: 2%;
    }
  }

  .titulo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: justify;
    @media (max-width: 1242px) {
      font-size: 20px; /* Ajusta mais em telas pequenas */
    }

    @media (max-width: 1186px) {
      font-size: 16px; /* Ajusta mais em telas pequenas */
    }

    @media (max-width: 1155px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
    }

    @media (max-width: 1027px) {
      font-size: 25px; /* Reduz tamanho da fonte em telas menores */
    }

    @media (max-width: 814px) {
      font-size: 24px; /* Ajusta mais em telas pequenas */
    }
    @media (max-width: 631px) {
      font-size: 24px; /* Ajusta mais em telas pequenas */
    }
    @media (max-width: 562px) {
      font-size: 24px; /* Ajusta mais em telas pequenas */
    }
    @media (max-width: 492px) {
      font-size: 20px; /* Ajusta mais em telas pequenas */
    }
    @media (max-width: 375px) {
      font-size: 18px; /* Ajusta mais em telas pequenas */
    }
    @media (max-width: 768px) {
      margin-top: -10%;
    }
    @media (max-width: 430px) {
      margin-top: -40%;
    }
    @media (max-width: 375px) {
      margin-top: 5%;
    }
  }

  .SubmoduleItem {
    text-align: justify;
    font-size: 20px;
    @media (max-width: 1155px) {
      font-size: 15px;
    }
    @media (max-width: 1625px) {
      font-size: 15px;
    }
    @media (max-width: 1027px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      text-align: start;
    }
    @media (max-width: 1017px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      margin-left: -51%;
    }
    @media (max-width: 923px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      margin-left: -48%;
    }
    @media (max-width: 867px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      margin-left: -40%;
    }
    @media (max-width: 753px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      margin-left: -35%;
    }
    @media (max-width: 677px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      margin-left: -30%;
    }
    @media (max-width: 649px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      margin-left: -20%;
    }
    @media (max-width: 639px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      margin-left: -25%;
    }
    @media (max-width: 583px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      margin-left: -20%;
      margin-bottom: 5%;
    }
    @media (max-width: 519px) {
      font-size: 20px; /* Reduz tamanho da fonte em telas menores */
      margin-left: 0%;
    }

    @media (max-width: 631px) {
      font-size: 20px; /* Ajusta mais em telas pequenas */
    }
    @media (max-width: 1087px) {
      font-size: 15px; /* Reduz tamanho da fonte em telas menores */
    }
    @media (max-width: 1186px) {
      font-size: 16px; /* Ajusta mais em telas pequenas */
    }
    @media (max-width: 1242px) {
      font-size: 16px; /* Ajusta mais em telas pequenas */
    }
  }

  .list {
    text-align: justify;
    font-size: 16px;

    @media (max-width: 1027px) {
      font-size: 14px; /* Reduz tamanho da fonte em telas menores */
      justify-content: flex-start;
    }
    @media (max-width: 631px) {
      font-size: 12px; /* Ajusta mais em telas pequenas */
    }
  }

  .button-container {
    display: flex;
    margin-left: 3%;
    gap: 10px; /* Espaçamento entre os botões */
  }

  .button-link {
    padding: 10px 20px;
    color: white;
    background-color: #ae1100;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    width: 150px; /* Ajuste a largura dos botões conforme necessário */

    @media (max-width: 1027px) {
      width: 100%; /* Botões ocupam largura total */
    }
  }

  .button-link:hover {
    background-color: #8c0900;
  }
`;

export const SubmoduleList = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40%; /* Ajuste conforme necessário */

  @media (max-width: 1027px) {
    max-width: 100%; /* Ocupa o espaço total em telas menores */
  }
`;

export const SubmoduleItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .play-icon {
    margin-right: 10px;
    color: #ae1100;
    font-size: 18px;

    @media (max-width: 631px) {
      font-size: 16px; /* Ajusta tamanho do ícone em telas pequenas */
    }
  }
`;
