import styled from 'styled-components';

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  margin: 0 auto; // Isso alinha a div no centro horizontalmente
  padding: 0 20px;
  margin-top: 35%;

  @media (max-width: 1596px) {
    margin-top: 70%;
  }
  @media (max-width: 1593px) {
    margin-top: 50%;
  }
  @media (max-width: 1377px) {
    margin-top: 50%;
  }

  @media (max-width: 1268px) {
    margin-top: 50%;
  }
  @media (max-width: 1149px) {
    margin-top: 60%;
  }
  @media (max-width: 768px) {
    margin-top: 70%;
  }
  @media (max-width: 540px) {
    margin-top: 100%;
    margin-left: -7%;
  }
  @media (max-width: 490px) {
    margin-top: 110%;
    margin-left: -7%;
  }
  @media (max-width: 360px) {
    margin-top: 140%;
    margin-left: -7%;
  }
  @media (max-width: 375px) {
    margin-top: 150%;
    margin-left: -7%;
  }
  .Progresso {
    margin-left: -55%;
    @media (max-width: 896px) {
      margin-left: 0%;
    }
  }
`;
export const Container = styled.section`
  overflow: hidden;
  overflow-y: auto;
  transition: margin-left 0.5s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1%;
  height: 100vh;
  z-index: 1;
  padding-left: 180px;

  @media (max-width: 1024px) {
    padding-left: 0px;
  }

  @media (max-width: 1024px) {
    padding-left: 0px;
  }
  background: #fff;

  .content {
    margin-top: 5%;
  }
`;
