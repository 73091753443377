import styled from 'styled-components';

export const Containered = styled.div`
  h1 {
    margin: 50% -50%;
    margin-bottom: 5%;
    text-align: center;

    @media (max-width: 1117px) {
      margin-top: 80%;
    }
    @media (max-width: 379px) {
      margin-top: 65%;
    }
  }

  .container {
    margin-top: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;

    button {
      margin-left: -10px !important;
      margin-top: 20px !important;
      width: 250px !important;
      color: white !important;
      font-weight: bold !important;
      background-color: #ae1100 !important;
      font-family: 'MyFont' !important;
      text-transform: none !important;
      &:hover {
        background-color: #ae1100 !important;
        transition-duration: 0s !important;
      }

      @media (max-width: 1024px) {
        width: 80%; /* Ajusta o tamanho do botão em telas menores */
      }

      @media (max-width: 500px) {
        width: 90%; /* Ajusta o botão para telas muito pequenas */
      }
    }

    .add-icon {
      width: 50px;
      height: 50px;
      margin: 0% 45%;

      @media (max-width: 1024px) {
        width: 40px; /* Diminui o ícone em telas menores */
        height: 40px;
      }

      @media (max-width: 500px) {
        width: 30px; /* Ajuste para telas pequenas */
        height: 30px;
      }
    }

    input {
      display: none;
    }

    input {
      display: none;
    }

    .img-container {
      margin-top: 30px;
      border-radius: 50%;
      border: 3px solid black;

      img {
        display: block;
        border-radius: 50%;
      }
    }
  }

  footer {
    margin-top: 100px;

    a {
      color: black;
      text-decoration: none;
    }
  }
  small {
    text-align: center;
    justify-self: center;
    margin-left: 5%;

    @media (max-width: 1024px) {
      font-size: 14px; /* Ajusta o tamanho da fonte em telas menores */
      margin-left: 0; /* Remove a margem para alinhar melhor */
    }

    @media (max-width: 500px) {
      font-size: 10px; /* Ajuste para telas pequenas */
    }
  }

  @media (max-width: 1024px) {
    h2 {
      font-size: 15px;
    }
    h1 {
      font-size: 30px; /* Ajusta o título em telas menores */
    }

    .container {
      margin-top: 5%; /* Ajusta a margem do container em telas menores */
    }

    footer {
      margin-top: 50px; /* Ajusta a margem inferior para telas menores */
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 24px; /* Ajusta o título para telas pequenas */
    }

    .container {
      margin-top: 3%; /* Ajusta ainda mais a margem superior */
    }

    footer {
      margin-top: 30px; /* Ajusta a margem inferior em telas muito pequenas */
    }
  }
`;
