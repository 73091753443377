import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FaEdit } from 'react-icons/fa';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { CollaboratorContainer } from './styled';
import { Container } from '../../styles/GlobalStyles';
import axios from '../../services/axios';

import Loading from '../../components/Loading';
import Edit from '../EditAdm';

export default function User() {
  const { id } = useParams();
  const [cnpj, setCnpj] = useState('');
  const [rhusers, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editUserId, setEditUserId] = useState(null);

  const [open, setOpen] = useState(false);

  const handleOpen = (userId) => {
    setEditUserId(userId);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get(`/empresa/${id}`);
        setCnpj(response.data.cnpj);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/rhusers');
        const empresaUsers = response.data.filter(
          (user) =>
            user.cnpj_enterprise === cnpj &&
            (user.departamento === 'Empresa' || user.departamento === 'Empresa + Premium'),
        );
        setUsers(empresaUsers);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (cnpj) {
      getData();
    }
  }, [cnpj]);

  return (
    <Container>
      <Loading isLoading={isLoading} />

      <CollaboratorContainer>
        <h1> Ajuste de Usuarios</h1>
        <h4>Atualize informações de usuários da empresa</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rhusers.map((empresa) => (
              <TableRow key={String(empresa.id)}>
                <TableCell>{empresa.nome}</TableCell>
                <TableCell>{empresa.email}</TableCell>
                <TableCell>
                  <Button type="button" onClick={() => handleOpen(empresa.id)} aria-label="Edit">
                    <FaEdit color="black" size={16} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
          open={open}
          onClose={handleClose}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          {handleClose && <Edit id={editUserId} handleClose={handleClose} />}
        </Modal>
      </CollaboratorContainer>
    </Container>
  );
}
