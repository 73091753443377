import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh; /* Garantir que o container ocupe a altura total da tela */
  background: #fff;
  overflow-x: hidden; /* Oculta a rolagem horizontal */
  overflow-y: auto; /* Permite rolagem vertical se o conteúdo ultrapassar a altura da tela */
  z-index: 0;

  /* Ajustes para dispositivos móveis */
  @media (max-width: 430px) {
    width: 100%;
    /* Ajuste do padding para evitar que o conteúdo fique coberto */
    padding: 0 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1px; /* Ajusta o padding para telas de tamanho médio */
  }
  h2 {
    font-family: 'MyFont';
    font-size: 20px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 430px) {
      font-size: 20px;
    }
  }
  .table {
    font-size: 20px;
    @media (max-width: 430px) {
      font-size: 20px;
      width: 100%;
      display: block;
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative; /* Garantir que os elementos posicionados de forma absoluta fiquem dentro do contêiner */
  overflow-x: hidden; /* Evita que o conteúdo ultrapasse horizontalmente */

  @media (max-width: 1368px) {
    flex-direction: column; /* Empilha os elementos verticalmente */
    align-items: stretch; /* Garante que os elementos se ajustem ao contêiner */
    height: auto; /* Permite ajuste automático */
    overflow-y: auto;
  }

  @media (max-width: 430px) {
    width: 100%;
    flex-direction: column;
  }

  .green-text {
    color: green;
  }

  .table1 {
    font-family: 'MyFont';
    margin-bottom: 20px;
  }

  .table1-button {
    width: 100%; /* Faz com que o botão ocupe 100% da largura */
  }
`;

/* export const SidebarWrapper = styled.div`
  width: ${({ isVisible }) => (isVisible ? '250px' : '0')};
  transition: width 0.3s ease;
  overflow: hidden;
  flex-shrink: 0; /* Impede que o sidebar encolha quando fechado */

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .acordeon {
    /* Exemplo de estilos do acordeão */
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .acordeon.active {
    /* Estilo para quando o acordeão está ativo */
    background-color: #f1f1f1;
  }

  label {
    flex-direction: column;
    font-family: 'MyFont';
    margin-bottom: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  button {
    margin: 20px 0;
    width: 150px;
    color: white;
    font-weight: bold;
    background-color: #ae1100;
    font-family: 'MyFont';
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  input {
    height: 40px;
    font-size: 18px;
    font-family: 'MyFont';
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin: 10px 0;
    &:focus {
      border: 1px solid #ae1100;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  select {
    width: 250px;
    height: 40px;
    font-family: 'MyFont';
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
  }
`;

export const Title = styled.h1`
  text-align: left;
  font-family: 'MyFont';
  font-size: 17px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    height: 80px;
    width: 80px;
    margin: 10px;
    position: relative;
    font-family: 'MyFont';
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
    }

    &:checked {
      background-color: #ae1100;

      &::after {
        left: 20px;
      }
    }

    &:hover {
      background-color: #c9cbcd;
      transition-duration: 0s;

      &:checked {
        background-color: #ae1100;
      }
    }

    &:focus:not(.focus-visible) {
      outline: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 50%;
      transition: all 100ms ease-out;
    }
  }
`;

export const StyledButton = styled.button`
  align-items: center;
  background-color: #ae1100;
  color: white;
  width: 100%;
  border: none;
  flex-direction: column;
  font-weight: bold;
  font-family: 'MyFont';
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #8b0a00;
  }

  svg {
    margin-right: 0px;
    width: 15px;
    height: 15px;
  }

  h3 {
    font-family: 'MyFont';
    font-size: 20px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  h5 {
    font-family: 'MyFont';
    font-size: 20px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  h2 {
    font-family: 'MyFont';
    font-size: 20px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 430px) {
      font-size: 20px;
    }
  }
  h1 {
    font-family: 'MyFont';
    font-size: 20px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .questoes {
    margin: 3% 0;
    font-family: 'MyFont';
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .table {
    font-family: 'MyFont';
    padding: 5px;
  }
  @media (max-width: 430px) {
    width: 100%;
    height: auto; /* Para ajuste proporcional */
    display: block;
  }
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  font-family: 'MyFont';
  width: 100%;
  padding: 10px; /* Adiciona um pequeno espaçamento interno */
  position: relative;
  @media (max-width: 1464px) {
    margin-top: 5%;
  }
  @media (max-width: 505px) {
    margin-top: 15%;
  }
  iframe {
    width: ${({ isSidebarOpen }) => (isSidebarOpen ? '960px' : '90%')};
    height: ${({ isSidebarOpen }) => (isSidebarOpen ? '160px' : 'calc(100vh - 130px)')};
    margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '160px' : '0%')};
    transition: width 0.9s ease, height 0.9s ease;
    margin-top: 5%;
    object-fit: contain;
    @media (max-width: 1508px) {
      width: 90%;
      height: 100vh;
    }
    @media (max-width: 1464px) {
      width: 65%;
      height: 100vh;
    }
    @media (max-width: 1236px) {
      width: 100%;
      margin-bottom: 5%;
      height: 100vh;
    }
    @media (max-width: 1006px) {
      width: 100%;
      margin-bottom: 5%;
      height: 90vh;
    }
    @media (max-width: 924px) {
      width: 100%;
      margin-bottom: 5%;
      height: 85vh;
    }
    @media (max-width: 1266px) {
      width: 100%;
      margin-bottom: 5%;
      height: 85vh;
    }
    @media (max-width: 1071px) {
      width: 100%;
      margin-bottom: 5%;
      height: 75vh;
    }
    @media (max-width: 962px) {
      width: 100%;
      margin-bottom: 5%;
      height: 70vh;
    }
    @media (max-width: 884px) {
      width: 100%;
      margin-bottom: 5%;
      height: 80vh;
    }
    @media (max-width: 884px) {
      width: 100%;
      margin-bottom: 5%;
      height: 80vh;
    }
    @media (max-width: 893px) {
      width: 100%;
      margin-bottom: 5%;
      height: 65vh;
    }
    @media (max-width: 843px) {
      width: 100%;
      margin-bottom: 5%;
      height: 55vh;
    }
    @media (max-width: 843px) {
      width: 100%;
      margin-bottom: 5%;
      height: 55vh;
    }
    @media (max-width: 774px) {
      width: 100%;
      margin-bottom: 5%;
      height: 50vh;
    }
    @media (max-width: 766px) {
      width: 100%;
      margin-bottom: 5%;
      height: 45vh;
    }
    @media (max-width: 706px) {
      width: 100%;
      margin-bottom: 5%;
      height: 50vh;
    }
    @media (max-width: 664px) {
      width: 100%;
      margin-bottom: 5%;
      height: 50vh;
    }
    @media (max-width: 687px) {
      width: 100%;
      margin-bottom: 5%;
      height: 40vh;
    }
    @media (max-width: 568px) {
      width: 100%;
      margin-bottom: 5%;
      height: 40vh;
    }
    @media (max-width: 568px) {
      width: 100%;
      margin-bottom: 5%;
      height: 30vh;
    }
    @media (max-width: 425px) {
      width: 100%;
      margin-bottom: 5%;
      height: 25vh;
    }
  }

  .button-container {
    display: flex;
    margin-left: 3%;
    justify-content: flex-start; /* Alinha os botões no centro da tela */
    flex-wrap: wrap; /* Permite que os botões fiquem empilhados em telas menores */
    gap: 10px; /* Adiciona um espaçamento consistente entre os botões */
  }

  .button-quiz {
    flex: 1 1 auto; /* Faz os botões ocuparem espaço proporcional */
    max-width: 300px; /* Limita o tamanho máximo dos botões */
    padding: 10px 20px;
    margin: 10px 0; /* Espaçamento vertical para quando os botões empilharem */
    background-color: #ae1100;
    color: white;
    border: none;
    font-weight: bold;
    font-family: 'MyFont';
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #8b0a00;
    }
  }

  .question {
    display: flex;
    align-items: center;
    font-family: 'MyFont';
    font-size: 25px;
    margin: 10% 3%;
    margin-bottom: 2%;
    font-weight: bold;
    transition: background-color 0.3s ease;
    max-width: 800px; /* Limita a largura máxima do texto */
    word-wrap: break-word; /* Garante que palavras longas sejam quebradas */
    overflow-wrap: break-word; /* Garante consistência em navegadores modernos */
    @media (max-width: 1104px) {
      margin-top: 10%;
    }
    @media (max-width: 702px) {
      margin-top: 10%;
    }
    @media (max-width: 470px) {
      margin-top: 10%;
      font-size: 22px;
    }
  }

  .answer-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0; /* cor de fundo ao passar o mouse */
    }
  }

  input[type='radio'] {
    width: 16px; /* Tamanho do botão de rádio */
    height: 16px;
    border: 2px solid #007bff;
    border-radius: 50%; /* Estilo arredondado */
    cursor: pointer;
    flex-shrink: 0; /* Impede que o botão de rádio encolha */

    &:hover {
      border-color: #0056b3;
    }

    &:checked::before {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #007bff;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }
  @media (max-width: 768px) {
    li {
      flex-wrap: wrap; /* Permite que o texto quebre linhas caso necessário */
    }

    label {
      font-size: 18px; /* Reduz o tamanho do texto */
    }

    input[type='radio'] {
      width: 14px; /* Reduz o tamanho do botão de rádio */
      height: 14px;
    }
  }

  label {
    display: block; /* Garante que cada label fique em uma linha separada */
    max-width: 100%; /* Limita a largura ao tamanho do container */
    max-width: 800px; /* Limita a largura máxima do texto */
    word-wrap: break-word; /* Garante que palavras longas sejam quebradas */
    overflow-wrap: break-word;
    margin: 1% 0%;
    &:hover {
      background-color: #ccc;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  .radio {
    width: 16px;
    height: 16px;
    margin-left: 3%;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    display: inline-block; /* Faz com que o botão de rádio fique ao lado do texto */
    &:hover {
      border-color: #0056b3; /* cor da borda ao passar o mouse */
    }

    &:checked::before {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #007bff;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  .radio + label {
    display: inline-flex; /* Flexbox para alinhar o rádio e o texto lado a lado */
    align-items: center; /* Alinha verticalmente o radio e o texto */
    margin-left: 5px;
    cursor: pointer;
    max-width: calc(100% - 24px); /* Limita a largura do texto (radio + label) */

    &:hover {
      color: #0056b3; /* cor do texto ao passar o mouse */
    }
    ul {
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
    }

    li {
      display: flex;
      align-items: center;
      flex-wrap: wrap; /* Permite que o texto quebre se necessário */
      margin-bottom: 10px; /* Espaçamento entre as alternativas */
    }

    li input {
      flex-shrink: 0; /* Impede que o input encolha */
    }
  }
  h2 {
    font-size: 20px;
    margin-top: 5%;
    margin-bottom: -4%;
    color: black;
  }
  .p {
    display: block; /* Garante que cada label fique em uma linha separada */
    max-width: 100%; /* Limita a largura ao tamanho do container */
    max-width: 800px; /* Limita a largura máxima do texto */
    word-wrap: break-word; /* Garante que palavras longas sejam quebradas */
    overflow-wrap: break-word;
    font-size: 20px;
    margin-left: 3%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column; /* Empilha os elementos verticalmente */
  gap: 12px; /* Espaçamento entre os elementos */
`;

export const SidebarWrapper = styled.div`
  width: ${({ isVisible }) => (isVisible ? '250px' : '0')};
  transition: width 0.3s ease;
  overflow: hidden;
  flex-shrink: 0;
`;

export const Sidebar = styled.div`
  width: 450px; /* Largura do sidebar */
  position: absolute;
  transition: width 0.3s ease;
  overflow: auto;
  right: 0;
  max-height: 100vh;
  margin-top: 6%;
  z-index: 1;
  font-family: 'MyFont';
  padding: 20px;

  @media (max-width: 1368px) {
    position: relative; /* Muda de absoluto para relativo */
    margin-top: 2px; /* Adiciona margem para separar do conteúdo acima */
    width: 100%; /* Ajusta a largura para ocupar toda a tela */
    order: 2; /* Certifica que ele aparece após o conteúdo principal */
  }

  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const MainContent = styled.div`
  flex: 1;
  transition: margin-left 0.5s ease, width 0.5s ease;
  margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '300px' : '0')};
  width: ${({ isSidebarOpen }) => (isSidebarOpen ? 'calc(100% - 300px)' : '100%')};

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
  p {
    font-family: 'MyFont';
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  input[type='radio'] {
    width: 16px; /* Tamanho do botão de rádio */
    height: 16px;
    border: 2px solid #007bff;
    border-radius: 50%; /* Estilo arredondado */
    cursor: pointer;
    flex-shrink: 0; /* Impede que o botão de rádio encolha */

    &:hover {
      border-color: #0056b3;
    }

    &:checked::before {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #007bff;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  .radio + label {
    display: inline-block;
    cursor: pointer;

    &:hover {
      background-color: #ccc;
    }
  }
  .radio {
    margin-right: 10px;
  }

  .radio:checked + label {
    background-color: #ccc; /* cor de fundo quando o rádio está selecionado */
    color: #ae1100;
  }

  .radio + label {
    display: inline-flex; /* Flexbox para alinhar o rádio e o texto lado a lado */
    align-items: center; /* Alinha verticalmente o radio e o texto */
    margin-left: 5px;
    cursor: pointer;
    white-space: nowrap; /* Impede a quebra de linha no texto */
    font-size: 15px;
    transition: color 0.3s ease;
  }
  .radio + label:hover {
    color: #ae1100; /* cor de texto ao passar o mouse */
  }
  ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }

  li {
    display: flex; /* Garante alinhamento horizontal */
    align-items: center; /* Alinha verticalmente o rádio e o texto */
    flex-wrap: nowrap; /* Evita que o texto quebre linhas */
    margin-bottom: 10px; /* Espaçamento entre os itens */
    gap: 10px; /* Espaçamento entre o rádio e o texto */
  }

  li input {
    flex-shrink: 0; /* Impede que o input encolha */
  }
  input[type='radio'] {
    width: 16px; /* Tamanho ajustado para manter consistência */
    height: 16px;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &:hover {
      border-color: #0056b3; /* cor da borda ao passar o mouse */
    }
  }

  /* Estilo para a questão */
  .question {
    display: flex;
    align-items: center;
    font-family: 'MyFont';
    font-size: 20px;
    margin: 20px 20px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    padding: 5px;

    &:hover {
      background-color: #f0f0f0; /* cor de fundo ao passar o mouse */
    }
  }

  .question:hover {
    background-color: #f0f0f0; /* cor de fundo ao passar o mouse */
  }

  .button-quiz {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #ae1100;
    color: white;
    width: 180px;
    margin-bottom: 10px;
    height: 40px; /* Adicione uma altura para ajudar na centralização vertical */
    border: none;
    margin: 20px 10px;
    font-weight: bold;
    font-family: 'MyFont';
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #8b0a00;
    }
  }

  .button-container {
    display: flex;
  }
  @media (max-width: 768px) {
    li {
      flex-wrap: wrap; /* Permite que o texto quebre linhas caso necessário */
    }

    label {
      font-size: 18px; /* Reduz o tamanho do texto */
    }

    input[type='radio'] {
      width: 14px; /* Reduz o tamanho do botão de rádio */
      height: 14px;
    }
  }
`;
