import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const DocumentManagementContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;
  margin-top: 12%;

  @media (max-width: 768px) {
    max-width: 90%; /* Reduz a largura para tablets */
    margin-top: 20%;
    padding: 15px; /* Reduz o padding */
  }

  @media (max-width: 480px) {
    max-width: 80%; /* Ajusta ainda mais a largura para smartphones */
    margin-top: 25%;
    padding: 10px; /* Reduz mais o padding */
  }

  h2 {
    text-align: center;
    margin-bottom: 10%;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 30px;
      padding: 15px;
      margin-top: 15%;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 25px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 20px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }

  h3 {
    margin-top: 20px;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 30px;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 15px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 15px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }
  .p {
    font-family: 'MyFont';
    @media (max-width: 1024px) {
      font-size: 20px;
    }

    @media (max-width: 540px) {
      font-size: 12px;
    }
  }

  .button {
    margin-bottom: 10px;
    padding: 8px 12px;
    width: 150px;
    color: white;
    text-transform: none;
    font-size: 15px;
    font-family: 'MyFont';
    font-weight: bold;
    background-color: #ae1100;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  @media (max-width: 768px) {
    .button {
      width: 120px; /* Ajusta a largura para tablets */
      font-size: 14px; /* Reduz o tamanho da fonte */
      padding: 6px 10px; /* Reduz o padding */
    }
  }

  @media (max-width: 480px) {
    .button {
      width: 100%; /* Faz o botão ocupar toda a largura do container */
      font-size: 10px; /* Reduz ainda mais o tamanho da fonte */
      padding: 4px 8px; /* Padding menor para economizar espaço */
    }
  }

  button:hover {
    background-color: #8c0900; /* Cor do fundo ao passar o mouse para outros botões */
  }

  /* Estilos para o botão de deletar */
  .delete-button {
    display: flex;
    color: #696969; /* Cor do ícone */
    border: none; /* Sem borda */
    cursor: pointer; /* Mantenha o cursor como pointer */
    margin-left: 10px; /* Espaçamento à esquerda do ícone */
  }

  /* Remove o efeito de hover */
  .delete-button:hover {
    background-color: transparent; /* Nenhuma alteração de cor no hover */
  }

  ul {
    list-style-type: none;
    padding: 0;
    @media (max-width: 1024px) {
      font-size: 20px;
    }

    @media (max-width: 540px) {
      font-size: 12px;
    }
  }

  li {
    display: flex; /* Alinha o conteúdo horizontalmente */
    align-items: center; /* Centraliza verticalmente os itens */
    margin-bottom: 8px; /* Espaçamento entre os itens da lista */
    overflow: hidden; /* Esconde o texto que ultrapassar a largura do li */
  }

  li a {
    color: #ae1100;
    text-decoration: underline;
    white-space: nowrap; /* Impede a quebra de linha no link */
    overflow: hidden; /* Esconde o texto que ultrapassa o contêiner */
    text-overflow: ellipsis; /* Adiciona "..." quando o texto é cortado */
    max-width: calc(100% - 40px); /* Ajuste a largura máxima (ajuste conforme necessário) */
  }
  .text-field {
    margin-bottom: 5%;
    font-family: 'MyFont';

    & label {
      font-size: 0.9rem; /* Tamanho padrão */
      transition: font-size 0.3s ease; /* Transição suave */
      font-family: 'MyFont';
    }

    & label.Mui-focused {
      color: #ae1100; /* Cor ao focar */
      font-size: 0.9rem; /* Tamanho da label ao focar */
    }

    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100; /* Borda ao focar */
      }

      /* Ajuste para o texto digitado */
      & input {
        font-size: 0.9rem; /* Tamanho padrão do texto digitado */
        font-family: 'MyFont';
      }
    }

    /* Responsividade */
    @media (max-width: 768px) {
      & label {
        font-size: 1.5rem; /* Ajuste para tablets */
        font-family: 'MyFont';
      }

      & .MuiOutlinedInput-root input {
        font-size: 1.1rem; /* Texto digitado menor para tablets */
        font-family: 'MyFont';
      }
    }

    @media (max-width: 480px) {
      & label {
        font-size: 1.3rem; /* Ajuste para celulares */
        font-family: 'MyFont';
      }

      & .MuiOutlinedInput-root input {
        font-size: 1.3rem; /* Texto digitado menor para celulares */
        font-family: 'MyFont';
      }
    }
  }
`;
