import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MyFont from '../../fonts/eurosoft.ttf';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'MyFont';
  height: 100vh;

  @media (max-width: 768px) {
    height: auto; /* Permite que o conteúdo ocupe a altura necessária */
    padding: 10px; /* Adiciona espaçamento interno */
  }

  @media (max-width: 480px) {
    flex-direction: column; /* Empilha os itens verticalmente */
    padding: 20px; /* Mais espaçamento para telas pequenas */
  }
`;

export const CollaboratorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  width: 80%;
  max-width: 900px;
  padding: 69px 20px;
  border-radius: 8px;
  margin-top: 7%;

  @media (max-width: 768px) {
    width: 90%; /* Ajusta a largura para ocupar mais espaço na tela */
    padding: 50px 15px; /* Reduz o padding */
    margin-top: 5%; /* Menor margem superior */
  }

  @media (max-width: 480px) {
    width: 95%; /* Quase a largura total para telas muito pequenas */
    padding: 30px 10px; /* Padding ainda menor */
    margin-top: 10%; /* Aumenta o espaçamento superior para melhor visualização */
  }

  h1 {
    margin-top: 5%;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 30px;
      padding: 15px;
    }
    @media (max-width: 768px) {
      margin-top: 20%;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 25px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 20px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
      margin-top: 25%;
      margin-bottom: -2%;
    }
  }
  h4 {
    text-align: start; /* Mantém o título centralizado */
    font-family: 'MyFont';
    margin-bottom: 2%;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 22px;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 15px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 15px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }

  .p {
    font-family: 'MyFont';
    color: black;
    @media (max-width: 1024px) {
      width: 200vw; /* Reduz a largura em tablets */
      max-width: 90%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 20px;
      padding: 15px;
      text-decoration: none;
    }

    @media (max-width: 540px) {
      width: 350vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 13px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
      text-decoration: none;
    }
    @media (max-width: 375px) {
      width: 350vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 11px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
      text-decoration: none;
    }
  }
  .select {
    font-family: 'MyFont';
    color: black;
    @media (max-width: 1024px) {
      font-size: 20px;
      margin-bottom: 5%;
    }

    @media (max-width: 540px) {
      font-size: 13px;
      margin-bottom: 5%;
    }
    @media (max-width: 375px) {
      font-size: 11px;
      margin-bottom: 5%;
    }
  }

  p:not(.name-filter) {
    font-family: 'MyFont';
    color: black;
    @media (max-width: 1024px) {
      width: 200vw; /* Reduz a largura em tablets */
      max-width: 90%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 20px;
      padding: 15px;
      text-decoration: none;
    }

    @media (max-width: 540px) {
      width: 350vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 15px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
      text-decoration: none;
    }
  }

  .time {
    @media (max-width: 550px) {
      margin-bottom: 5%;
    }
    @media (max-width: 768px) {
      margin-bottom: 5%;
    }
  }

  .button-container {
    text-align: start;
  }

  span {
    margin-right: 15px;
  }

  div + div {
    border-top: 1px solid #eee;
  }

  button {
    background-color: #ae1100;
    margin-top: 5px;
    margin: 0px 20px;
    margin-bottom: 2%;
    font-family: 'MyFont';
    font-weight: bold;
    @media (max-width: 768px) {
      width: 100%; /* Reduz o tamanho do botão em dispositivos menores */
      font-size: 14px; /* Ajusta o tamanho da fonte */
      padding: 10px; /* Adiciona mais espaço interno */
      margin-left: -0%;
    }

    @media (max-width: 480px) {
      width: 100%; /* Ainda menor para telas muito pequenas */
      font-size: 12px; /* Fonte ainda menor para caber melhor */
      margin-top: 5%; /* Ajusta as margens */
      margin-bottom: 5%;
    }
  }
  .button {
    background-color: #ae1100;
    margin-top: 2%;
    margin-left: 0%;
    font-family: 'MyFont';
    font-weight: bold;
    @media (max-width: 768px) {
      width: 100%; /* Reduz o tamanho do botão em dispositivos menores */
      font-size: 14px; /* Ajusta o tamanho da fonte */
      padding: 10px; /* Adiciona mais espaço interno */
    }

    @media (max-width: 480px) {
      width: 100%; /* Ainda menor para telas muito pequenas */
      font-size: 12px; /* Fonte ainda menor para caber melhor */
      margin-top: 5%; /* Ajusta as margens */
      margin-bottom: 5%;
    }
  }

  .input {
    width: 150px;
    height: 35px;
    margin-left: 0%;
    font-family: 'MyFont';
    margin-top: -5%;
  }

  h5 {
    font-size: 17px;
    margin-left: 0%;
    margin-bottom: 2%;
  }

  .checkbox {
    appearance: none;
    background-color: #ae1100;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 4;
    height: 20px;
    margin: 5px 0px;
    margin-left: 40%;
    position: relative;
    width: 30px;
    cursor: pointer;

    &::before {
      bottom: -6px;
      content: '';
      left: -6px;
      position: absolute;
      right: -6px;
      top: -6px;
    }

    &:checked {
      background-color: green;

      &::after {
        background-color: #fff;
        left: 13px;
      }
    }
    &:focus:not(.focus-visible) {
      outline: 0;
    }

    &::after {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 14px;
    }

    transition: all 100ms ease-out;
  }

  .expiration-date-cell {
    margin-left: 50%;
  }

  @media (max-width: 768px) {
    margin: 0 auto; /* Centraliza o conteúdo */
    width: 80%; /* Define a largura para 80% da tela */
  }

  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    font-family: 'MyFont';

    th {
      padding: 10px 0px 0px 0px;
    }
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-family: 'MyFont';
    }

    tr:hover {
      background-color: #f5f5f5;
    }

    .edit-cell {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    margin-left: auto; /* Remova a margem à esquerda */
    margin-right: auto; /* Remova a margem à direita */
    display: block; /* Define como bloco para ocupar toda a largura disponível */
  }
`;

export const NewCollaborator = styled(Link)`
  display: block;
  padding: 20px 0 10px 0;
  font-family: 'MyFont';
`;

export const CenteredText = styled.h1`
  margin-top: 5%;
  text-align: center;
  margin-bottom: 50px;
  font-family: 'MyFont';
`;
