import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';

import Loading from '../../components/Loading';
import Footer from '../../components/Footer';
import { ReactComponent as Logo } from '../../assets/img/CyberSensei.svg';
import { Form, Composition, Container } from './styled';
import * as actions from '../../store/modules/auth/actions';
import axios from '../../services/axios';

function PasswordResetPage() {
  const dispatch = useDispatch();
  const history = useHistory(); // Use useNavigate para redirecionar
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [setor, setSetor] = useState([]);
  const [funcao, setFuncao] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState(null);
  const [selectedFuncao, setSelectedFuncao] = useState(null);
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [empresa, setEmpresa] = useState({});
  const [cnpj, setCnpj] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [formStep, setFormStep] = useState(1);
  useEffect(() => {
    setNome();
    setSobrenome();
    setDepartamento();
    setEmail();
  }, []);

  const handleSubmitToken = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.get('/empresa');
      const userEmpresa = response.data.find((empresaData) => empresaData.token_enterprise === token);
      const userCollaborator = response.data.find((empresaData) => empresaData.token_collaborator === token);
      if (userEmpresa) {
        if (userEmpresa.profilebelt === 'Premium') {
          setDepartamento('Empresa + Premium');
        } else {
          setDepartamento('Empresa');
        }
        setEmpresa(userEmpresa);
        setCnpj(userEmpresa.cnpj);

        setFormStep(2);
      } else if (userCollaborator) {
        if (userCollaborator.profilebelt === 'Premium') {
          setDepartamento('colaborador + Premium');
        } else {
          setDepartamento('colaborador');
        }
        setEmpresa(userCollaborator);
        setCnpj(userCollaborator.cnpj);
        setFormStep(2);
      } else {
        alert('Token inválido ou expirado');
      }
    } catch (error) {
      console.error('Erro ao validar token:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const responseSectors = await axios.get('/sectors');
        const empresaSectors = responseSectors.data.filter((sector) => sector.cnpjCreator === cnpj);
        setSetor(Array.isArray(empresaSectors) ? empresaSectors : []);

        const responseFunctions = await axios.get('/functions');
        const empresaFunctions = responseFunctions.data.filter((func) => func.cnpjCreator === cnpj);
        setFuncao(Array.isArray(empresaFunctions) ? empresaFunctions : []);
      } catch (error) {
        console.error('Failed to fetch sectors and functions', error);
      }
    }

    getData();
  }, [cnpj]);

  const handlePrevStep = () => {
    setFormStep(1);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (empresa.used_quotas >= empresa.total_quotas || empresa.total_quotas === 0) {
      toast.error(
        'Atenção: O limite de usuários para sua empresa já foi excedido. Entre em contato com o responsável para mais detalhes.',
      );
      return;
    }

    const errors = {};
    if (!nome) errors.nome = 'Nome é obrigatório';
    if (!sobrenome) errors.sobrenome = 'Sobrenome é obrigatório';
    if (!email) errors.email = 'E-mail é obrigatório';
    // Adicione mais validações conforme necessário

    // Se o usuário é um colaborador, torna os campos setor e função obrigatórios
    if (departamento === 'colaborador') {
      if (!selectedSetor) errors.setor = 'Setor é obrigatório';
      if (!selectedFuncao) errors.funcao = 'Função é obrigatória';
    }

    if (!termsAccepted) {
      errors.termos = 'Você deve aceitar os Termos de Uso';
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return; // Pare o envio do formulário até que os erros sejam corrigidos
    }

    const randomPassword = Math.random().toString(36).slice(-8);

    try {
      dispatch(
        actions.registerRequest({
          cnpj_enterprise: empresa.cnpj,
          nome,
          sobrenome,
          setor: selectedSetor,
          funcao: selectedFuncao,
          email,
          departamento,
          password: randomPassword,
          empresaId: empresa.id,
        }),
      );

      history.push('/login');
    } catch (error) {
      console.error('Erro ao criar conta:', error); // Loga o erro
      alert('Erro desconhecido ao criar a conta. Tente novamente.');
    }
  };

  const handleNomeChange = (e) => {
    setNome(e.target.value);
  };

  const handleSobrenomeChange = (e) => {
    setSobrenome(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      {formStep === 1 ? (
        <Form onSubmit={handleSubmitToken}>
          <Logo className="logo" />
          <h1>Insira Código</h1>

          <TextField
            className="text-field"
            fullWidth
            InputProps={{ disableUnderline: true }}
            onChange={(e) => setToken(e.target.value)}
            placeholder="Insira Código de validação"
          />
          <div className="button-container">
            <Link className="link" to="/login">
              <button className="buttonLink" type="button">
                Voltar
              </button>
            </Link>
            <button type="submit" disabled={isLoading || token === ''}>
              Próximo
            </button>
          </div>
        </Form>
      ) : (
        formStep === 2 && (
          <Composition>
            <Logo className="logo" />
            <h1>Insira Seus Dados</h1>
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
              InputLabelProps={{
                style: {
                  color: '#ae1100', // Cor vermelha da label
                },
              }}
              label="Nome *"
              value={nome}
              onChange={handleNomeChange}
              placeholder="Nome"
              margin="normal"
              fullWidth
              error={!!fieldErrors.nome}
              helperText={fieldErrors.nome}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
              InputLabelProps={{
                style: {
                  color: '#ae1100', // Cor vermelha da label
                },
              }}
              label="Sobrenome *"
              value={sobrenome}
              onChange={handleSobrenomeChange}
              placeholder="Seu sobrenome"
              fullWidth
              error={!!fieldErrors.sobrenome}
              helperText={fieldErrors.sobrenome}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
              InputLabelProps={{
                style: {
                  color: '#ae1100', // Cor vermelha da label
                },
              }}
              label="E-mail *"
              type="email"
              value={email}
              fullWidth
              onChange={handleEmailChange}
              placeholder="Seu e-mail"
              margin="normal"
              error={!!fieldErrors.email}
              helperText={fieldErrors.email}
            />

            {departamento === 'colaborador' && (
              <>
                <Autocomplete
                  className="autocomplete"
                  id="combo-box-demo"
                  options={setor.map((sector) => sector.sector)}
                  getOptionLabel={(option) => option || ''}
                  onChange={(event, newValue) => setSelectedSetor(newValue)}
                  value={selectedSetor}
                  renderInput={(params) => (
                    <TextField
                      className="text-field"
                      {...params}
                      label="Setor"
                      inputProps={{ ...params.inputProps, disableUnderline: true }}
                      error={!!fieldErrors.setor}
                      helperText={fieldErrors.setor}
                    />
                  )}
                  classes={{
                    option: 'custom-option',
                  }}
                />
                <Autocomplete
                  className="autocomplete"
                  id="combo-box-demo"
                  options={funcao.map((func) => func.function)}
                  getOptionLabel={(option) => option || ''}
                  onChange={(event, newValue) => setSelectedFuncao(newValue)}
                  value={selectedFuncao}
                  renderInput={(params) => (
                    <TextField
                      className="text-field"
                      {...params}
                      label="Função"
                      inputProps={{ ...params.inputProps, disableUnderline: true }}
                      error={!!fieldErrors.funcao}
                      helperText={fieldErrors.funcao}
                    />
                  )}
                  classes={{
                    option: 'custom-option',
                  }}
                />
              </>
            )}
            <div className="terms-container">
              Eu aceito os
              <Link to="/termo" target="_blank" rel="noopener noreferrer">
                Termos de Uso
              </Link>
              <FormControlLabel
                className="terms-container"
                control={
                  <Checkbox
                    className="Checkbox"
                    id="terms-checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    color="primary"
                  />
                }
              />
            </div>

            {fieldErrors.termos && (
              <div style={{ color: 'red', marginTop: '5px', fontSize: '15px' }}>{fieldErrors.termos}</div>
            )}
            <div>
              <button className="button-container" type="button" onClick={handlePrevStep}>
                Voltar
              </button>
              <button className="button-container" type="submit" onClick={handleRegister}>
                Finalizar
              </button>
            </div>
          </Composition>
        )
      )}
      <Footer />
    </Container>
  );
}

export default PasswordResetPage;
