import axios from '../../services/axios';

function calculateCompletionPercentage(passedSubmodules, submodules) {
  const completedSubmodules = passedSubmodules.length;
  const totalSubmodules = submodules.length;
  return (completedSubmodules / totalSubmodules) * 100;
}

export default async function checkQuizAnswers(
  answers,
  submoduleId,
  questions,
  minScore,
  isLast,
  userId,
  courseId,
  submodules,
  passedSubmodules,
) {
  let correctAnswersCount = 0;
  console.log(isLast);
  questions.forEach((question) => {
    const userAnswer = answers[`question-${question.id}`];
    const correctAnswer = question.answers.find((answer) => answer.correct);
    if (userAnswer && userAnswer.id === correctAnswer.id) {
      correctAnswersCount += 1;
    }
  });

  const result = correctAnswersCount >= minScore ? 'aprovado' : 'reprovado';
  console.log(result);

  if (result === 'aprovado') {
    try {
      const idUserCourse = await axios.get('/usercourse');
      console.log('idUserCourse data:', idUserCourse.data);
      const mycourse = idUserCourse.data.find(
        (course) => String(course.userId) === String(userId) && String(course.courseId) === String(courseId),
      );
      console.log('mycourse:', mycourse);
      const newPassedSubmodules = [...passedSubmodules, { submodule_id: submoduleId, passedQuiz: true }];

      const percentage = calculateCompletionPercentage(newPassedSubmodules, submodules);
      console.log('Completion Percentage:', percentage);

      await axios.put(`/usercourse/${mycourse.id}`, {
        completionPercentage: percentage,
      });

      await axios.post('/usersubmodule', {
        userId,
        submodule_id: submoduleId,
        quizScore: correctAnswersCount,
        passedQuiz: true,
      });

      if (isLast) {
        await axios.put(`/rhusers/${userId}`, {
          graduacao: +1,
        });
        setFinalMessage('Parabéns por essa graduação! Você concluiu o treinamento e ganhara uma mudança de faixa!.');
      }
    } catch (error) {
      console.error('Error updating user course and submodule:', error);
    }
  }

  return result;
}
