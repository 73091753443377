import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 90vw; /* Ajuste a largura para adaptação em telas menores */
  max-width: 600px; /* Limita a largura máxima em telas grandes */
  height: auto; /* Permite que a altura seja ajustada ao conteúdo */
  max-height: 85vh; /* Limita a altura máxima */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  overflow-y: auto; /* Permite rolagem apenas vertical */
  overflow-x: hidden; /* Impede rolagem horizontal */
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Estilos para dispositivos móveis */
  @media (max-width: 768px) {
    width: 90vw; /* Reduz a largura em tablets */
    max-width: 80%; /* Mais flexível em telas menores */
    height: auto;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 80vw; /* Quase ocupa toda a largura da tela */
    padding: 0px;
    border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
  }
`;
export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: 30px;
  display: flex;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 980px;
  max-height: 1680px;
  margin: -5% auto 0%;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  h1 {
    font-family: 'MyFont';
    margin-top: 10%;
    margin-bottom: 1%;
  }
  /* width: 100%; */
  flex-direction: column;

  align-items: center;
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 15px;
    font-size: 15px;
    font-family: 'MyFont';
  }
  .custom-textarea {
    width: 480px;
    min-height: 200px; /* Ajuste a altura conforme necessário */
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 15px;
    border-radius: 4px;
    margin: 2px 0px 10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;
  }
  h1 {
    margin: -25px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: -1%;
    font-family: 'MyFont';
    align-items: start;
  }
  input {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    font-family: 'MyFont';
    margin: 2px 0px 10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  select {
    width: 100%; /* Modificado para ocupar toda a largura disponível */
    margin-top: 5px;

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    width: 30vh;
  }
  button:not(.teste) {
    display: flex;
    margin-left: 0px;
    margin-top: 10px;
    width: 150px;
    color: white;
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    background-color: #ae1100;
    font-family: 'MyFont';
    z-index: 1000;
  }
  button {
    margin: 20px 15px; /* Margem entre os botões */
    width: 150px;
    color: white;
    display: flex;
    background-color: #ae1100;
    font-size: 15px;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    border: none; /* Remova a borda para evitar espaçamento extra */
  }
  .text-field {
    width: 87%;
    margin-top: 3%;
    margin-bottom: 3%;

    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 7px;
    margin-top: 50px;
    width: 480px;
    border-radius: 8px;
    margin-bottom: 10px;
    @media (max-width: 1024px) {
      width: 60vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      padding: 15px;
    }
    @media (max-width: 480px) {
      width: 80vw; /* Quase ocupa toda a largura da tela */
      border-radius: 10px;
      margin-top: 20%;
      margin-bottom: 13%;
    }
    @media (max-width: 430px) {
      width: 70vw; /* Quase ocupa toda a largura da tela */
      border-radius: 10px;
      margin-top: 20%;
      margin-bottom: 13%;
    }
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 50%;
    background-color: white;
    @media (max-width: 480px) {
      margin-right: 0%;
      margin-bottom: 3%;
    }
  }

  .button-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
`;
