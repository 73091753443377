import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Divider } from '@mui/material';

import Modal from '@mui/material/Modal';

import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer, NoHoverButton, Button, Icon, Bloco } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import NewFunction from '../NewFunction';
import AjustFunction from '../FuncaoManager';

export default function User() {
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [editingId, setEditingId] = useState(null);

  const handleOpenAjust = (id) => {
    setEditingId(id);
  };
  const handleCloseAjust = () => {
    setEditingId(null);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirmOpen = (funcId) => {
    setDeleteId(funcId);
    setConfirmOpen(true);
  };
  const handleConfirmClose = () => setConfirmOpen(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const cnpj = useSelector((state) => state.auth.user.cnpj_enterprise);
  const [functions, setFunctions] = useState([]);
  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/functions');
        const empresaFunctions = response.data.filter((func) => func.cnpjCreator === cnpj);
        setFunctions(empresaFunctions);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, [cnpj]);

  const handleDelete = async () => {
    if (deleteId === null) return;

    try {
      setIsLoading(true);
      await axios.delete(`/functions/${deleteId}`);
      const newFunctions = functions.filter((func) => func.id !== deleteId);
      setFunctions(newFunctions);
      setIsLoading(false);
    } catch (err) {
      const status = get(err, 'response.status', 0);

      if (status === 401) {
        toast.error('Você precisa fazer login');
      } else {
        toast.error('Ocorreu um erro ao excluir o setor');
      }

      setIsLoading(false);
    }

    handleConfirmClose();
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />

      <CollaboratorContainer>
        <h1>Cadastrar Função</h1>
        <h4>Adicione uma nova função no sistema</h4>
        <button type="button" className="Create" onClick={handleOpen}>
          Nova
        </button>
        <Modal open={open} onClose={handleClose}>
          {handleClose && <NewFunction cnpj={cnpj} handleClose={handleClose} />}
        </Modal>
        <Divider />
        <h1 className="head">Ajustar Funções</h1>
        <h4>Atualize informações de funções existentes</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="p">Nome</TableCell>
              <TableCell className="p">Editar</TableCell>
              <TableCell className="p">Excluir</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {functions.map((func) => (
              <TableRow key={String(func.function)}>
                <TableCell className="p">{func.function}</TableCell>
                <TableCell>
                  <NoHoverButton
                    type="button"
                    aria-label="Edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenAjust(func.id);
                    }}
                  >
                    {' '}
                    <FaEdit color="black" size={16} />
                  </NoHoverButton>
                  {editingId === func.id && (
                    <Modal
                      open={editingId === func.id}
                      BackdropProps={{
                        style: {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                      onClose={handleCloseAjust}
                    >
                      <AjustFunction id={func.id} handleClose={handleCloseAjust} />
                    </Modal>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    className="my-special-button"
                    aria-label="Excluir"
                    style={{ marginLeft: '10px' }}
                    onClick={() => handleConfirmOpen(func.id)}
                  >
                    <Icon viewBox="0 0 15 17.5" height="17.5" width="15" xmlns="http://www.w3.org/2000/svg">
                      <path
                        transform="translate(-2.5 -1.25)"
                        d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                      />
                    </Icon>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CollaboratorContainer>

      <Modal open={confirmOpen} onClose={handleConfirmClose}>
        <Bloco>
          <div className="box-container">
            <h2>Confirmação</h2>
            <p>Tem certeza de que deseja deletar este setor?</p>
            <button type="button" onClick={handleDelete}>
              Sim
            </button>
            <button type="button" onClick={handleConfirmClose}>
              Não
            </button>
          </div>
        </Bloco>
      </Modal>
    </Container>
  );
}
