import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  background-color: white;
  display: flex;
  width: 150vh;
  height: 110vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 60%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  padding: 150px 70px;
  margin: -10px 25px;
`;

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  max-width: 1580px;
  margin: -5% auto 0%;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  display: grid;
  margin-left: 10%;
  grid-template-columns: repeat(2, 1fr); /* Duas colunas de largura igual */
  gap: 10px; /* Espaçamento entre os itens do grid */
  h1 {
    margin: -2px 0px;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    margin-bottom: -5px;
    font-family: 'MyFont';
    align-items: start;
    margin-top: 10%;
  }
  h4 {
    margin: 5px 10px;
    font-size: 20px;
    font-family: 'MyFont';
  }
  p {
    font-size: 15px;
  }

  button:not(.button-eye) {
    margin-left: 15px;
    width: 150px;
    height: 55px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    margin: 5px;
    font-weight: bold;
  }
  label {
    font-family: 'MyFont';
    display: flex;
    font-size: 15px;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 15px;
    margin: 5px 5px;
    font-family: 'MyFont';
  }
  &:focus {
    border: 1px solid #ae1100;
  }
  input {
    font-family: 'MyFont';
    width: 480px;
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin: 2px 0px 10px 0px;
    box-sizing: border-box;
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  select {
    width: 100%;
    margin-top: 5px;
    font-size: 15px;
    font-family: 'MyFont';
  }

  @media (max-width: 728px) {
    input,
    select {
      width: 80%;
      font-size: 15px;
      font-family: 'MyFont';
    }
  }

  @media (max-width: 425px) {
    max-height: 100vh;
    padding: 10px; /* Adicionei o padding aqui */
    input,
    select {
      width: 100%;
    }
  }
  .text-field {
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
        font-family: 'MyFont';
      }
    }
    @media (max-width: 1320px) {
      width: 150%;
    }
    @media (max-width: 1203px) {
      width: 170%;
      gap: 10px -60px;
    }

    @media (max-width: 1255px) {
      width: 180%;
      margin-left: 8px;
      margin-right: -15px;
    }

    @media (max-width: 1130px) {
      width: 140%;
      gap: 10px -50px;
    }

    @media (max-width: 1311px) {
      width: 180%;
      gap: 10px 50px;
    }

    @media (max-width: 1189px) {
      width: 150%;
      margin-left: 8px;
      margin-right: -85px;
    }
    @media (max-width: 1307px) {
      width: 190%;
      margin-left: 8px;
      margin-right: -85px;
    }

    @media (max-width: 1049px) {
      width: 180%;
      margin-left: 8px;
      margin-right: -85px;
    }

    @media (max-width: 978px) {
      width: 160%;
      margin-left: 8px;
      margin-right: -125px;
    }
  }

  @media (max-width: 1320px) {
    margin-left: 7%;
  }
  @media (max-width: 1203px) {
    margin-left: 10%;
  }

  @media (max-width: 1130px) {
    margin-left: 15%;
  }
`;

export const InputWrapper = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  flex-direction: row;

  input {
    display: flex;
    margin: 10px;
    font-size: 15px;
    font-family: 'MyFont';
    .MuiTextField-root {
      flex: 1;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 15px;
    margin: 10px 20px;
    font-size: 15px;
    font-family: 'MyFont';
  }
`;

export const CheckboxWrapper = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
    font-family: 'MyFont';
  }
  input[type='checkbox'] {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
    margin: 10px 10px;
    text-align: left;
    font-size: 15px;
    cursor: default;
    cursor: pointer;
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      margin-left: 15px;
      margin: 10px 10px;
      font-size: 15px;
      font-family: 'MyFont';
      .text {
        margin-right: 10px;
        font-size: 15px;
        font-family: 'MyFont';
      }
    }

    .text input[type='checkbox'] {
      font-family: 'MyFont';
      margin-left: 60%;
      font-size: 15px;
      font-family: 'MyFont';
    }

    &::before {
      bottom: -6px;
      content: '';
      left: -6px;
      position: absolute;
      right: -6px;
      top: -6px;
    }

    &:checked {
      background-color: #ae1100;

      &::after {
        background-color: #fff;
        left: 13px;
      }
    }

    &:hover {
      background-color: #c9cbcd;
      transition-duration: 0s;

      &:checked {
        background-color: #ae1100;
      }
    }

    &:focus:not(.focus-visible) {
      outline: 0;
    }

    &::after {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 14px;
    }

    transition: all 100ms ease-out;
  }
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 15px;
    margin: 10px 10px;
    font-size: 15px;
    font-family: 'MyFont';
  }
`;
