import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Button = styled.button`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  width: 55px; /* Aumente o tamanho do botão */
  height: 55px; /* Aumente o tamanho do botão */
  font-family: 'MyFont';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  position: fixed; /* Faz o botão flutuar */
  bottom: 20px; /* Posiciona o botão 20px acima do fundo da tela */
  right: 30px; /* Posiciona o botão 30px à esquerda da borda direita da tela */
  @media (max-width: 1100px) {
    width: 0.1rem;
    height: 0.1rem;
  }

  &:hover {
    transition: all 0.3s ease;
    transform: scale(1.05);
    color: #ae1100;
    border-radius: 20px;
    box-shadow: 1px 1px 7px #ae1100, 1px 2px 8px #ae1100;
  }

  &:active {
    transform: scale(0.98);
  }
  .img {
    width: 7rem;
    height: 7rem;
    @media (max-width: 1100px) {
      width: 8rem;
      height: 8rem;
    }
    @media (max-width: 509px) {
      width: 10rem;
      height: 10rem;
    }
    @media (max-width: 437px) {
      width: 13rem;
      height: 13rem;
    }
  }
`;

export const Tooltip = styled.span`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  position: absolute;
  top: -40px;
  color: #ae1100;
  border-radius: 5px;
  font-size: 12px;
  padding: 8px 12px;
  font-family: 'MyFont';
  font-weight: 600;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.105);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s;
  min-width: 100px;

  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    content: '';
    background-color: var(--tooltip-color);
    bottom: -10%;
  }

  ${Button}:hover & {
    opacity: 1;
  }
`;
