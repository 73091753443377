import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MyFont from '../../fonts/eurosoft.ttf';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'MyFont';
  height: 100vh;
`;

export const CollaboratorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
    margin-top: 20%;
  }
  width: 80%;
  max-width: 900px;
  padding: 30px 20px;
  border-radius: 8px;
  h1 {
    margin-top: 10%;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 30px;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 25px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 20px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }
  h4 {
    font-size: 20px;
    text-align: start; /* Mantém o título centralizado */
    font-family: 'MyFont';
    margin-bottom: 2%;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 30px;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 16px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }
  .p {
    font-family: 'MyFont';
    color: black;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 90%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 20px;
      padding: 20px;
      text-decoration: none;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 15px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }

  margin-top: 4%;
  margin: 50px;
  div {
    display: flex;
    justify-content: space-between;
    padding: 5px 300px;
    margin-left: -30%;
  }
  .head {
    margin-top: 2%;
  }
  .Create {
    margin-top: 2%;
    margin-bottom: 2%;
    width: 25%;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
    /* Responsividade para dispositivos móveis */
    @media (max-width: 768px) {
      width: 100%; /* Reduz o tamanho do botão em dispositivos menores */
      font-size: 14px; /* Ajusta o tamanho da fonte */
      padding: 10px; /* Adiciona mais espaço interno */
    }

    @media (max-width: 480px) {
      width: 100%; /* Ainda menor para telas muito pequenas */
      font-size: 12px; /* Fonte ainda menor para caber melhor */
      margin-top: 5%; /* Ajusta as margens */
      margin-bottom: 5%;
    }
  }
  div + div {
    border-top: 1px solid #eee;
    padding: 20px 300px;
    margin-left: -30%;
  }
  table {
    width: 100%;
    margin: 0 auto; /* Centraliza a tabela horizontalmente */
    border-collapse: collapse;

    th,
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-family: 'MyFont';
    }

    tr:hover {
      background-color: #f5f5f5;
    }

    .edit-cell {
      display: flex;
      align-items: center;
    }
  }
`;

export const CreatorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  max-width: 900px;
  padding: 30px 20px;
  border-radius: 8px;
  h1 {
    text-align: start;
    font-family: 'MyFont';
    margin-bottom: 1%;
  }
  h4 {
    text-align: start; /* Mantém o título centralizado */
    font-family: 'MyFont';
    margin-bottom: 3%;
  }
  p {
    font-family: 'MyFont';
    color: black;
    text-decoration: underline;
  }

  div {
    display: flex;
    justify-content: space-between;
    padding: 5px 300px;
    margin-left: -30%;
  }

  div + div {
    border-top: 1px solid #eee;
    padding: 20px 300px;
    margin-left: -30%;
  }
  table {
    width: 100%;
    margin: 0 auto; /* Centraliza a tabela horizontalmente */
    border-collapse: collapse;

    th,
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-family: 'MyFont';
    }

    tr:hover {
      background-color: #f5f5f5;
    }

    .edit-cell {
      display: flex;
      align-items: center;
    }
  }
`;

export const NewCollaborator = styled(Link)`
  display: block;
`;

export const CenteredText = styled.h1`
  margin-top: 5%;
  margin-bottom: 50px;
  font-family: 'MyFont';
`;

export const NoHoverButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  align-items: center;
  gap: 5px; /* Espaço entre o ícone e o texto "Editar" */
`;

export const Button = styled.button`
  background-color: transparent;
  position: relative;
  border: none;

  &:hover::after {
    visibility: visible;
    opacity: 1;
    top: -50%;
  }
`;

export const Icon = styled.svg`
  display: inline-block; /* Torna o ícone um bloco de nível inline */
  vertical-align: middle; /* Alinha o ícone verticalmente ao centro */
  transform: scale(1.2);
  transition: 0.2s linear;

  & path {
    transition: 0.2s linear;
  }
`;

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 90vw; /* Ajuste a largura para adaptação em telas menores */
  max-width: 750px; /* Limita a largura máxima em telas grandes */
  height: auto; /* Permite que a altura seja ajustada ao conteúdo */
  max-height: 85vh; /* Limita a altura máxima */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  overflow-y: auto; /* Permite rolagem apenas vertical */
  overflow-x: hidden; /* Impede rolagem horizontal */
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Estilos para dispositivos móveis */
  @media (max-width: 768px) {
    width: 90vw; /* Reduz a largura em tablets */
    max-width: 90%; /* Mais flexível em telas menores */
    height: auto;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 95vw; /* Quase ocupa toda a largura da tela */
    padding: 0px;
    border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
  }

  .box-container {
    border: 2px solid #ddd;
    padding: 10px;
    width: 621px;
    border-radius: 8px;
    margin-bottom: -8px;
    @media (max-width: 768px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      margin-top: 0%;
      padding: 15px;
      margin-top: 2%;
      margin-bottom: 2%;
    }

    @media (max-width: 480px) {
      width: 80vw; /* Quase ocupa toda a largura da tela */
      border-radius: 10px;
      margin-top: 2%;
      margin-bottom: 2%;
    }
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 55%;
    background-color: white;
  }

  button {
    justify-content: center;
    margin: 10px 25px;
    width: 40%;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    margin-bottom: -1%;
    margin-left: 0%;
    text-transform: none;
    border: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }
  h2 {
    font-size: 30px;
    text-align: start; /* Mantém o título centralizado */
    font-family: 'MyFont';
    margin-bottom: 3%;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 30px;
      padding: 15px;
      margin-bottom: -3%;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 18px;
      margin-bottom: -3%;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }
  p {
    font-family: 'MyFont';
    color: black;
    margin-bottom: 3%;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 20px;
      padding: 20px;
      text-decoration: none;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 15px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }
`;
