import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from './styled';
import Report from '../Report';
import BugIcon from '../../assets/img/Bug (1).svg';

export default function BugReport() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <>
      {isLoggedIn && (
        <Button id="Bug" onClick={() => setIsPopupOpen(true)}>
          {/* Use o componente do SVG aqui */}
          <img className="img" src={BugIcon} alt="Bug Icon" />
          <Tooltip>Feedback</Tooltip>
        </Button>
      )}
      {isPopupOpen && <Report onClose={() => setIsPopupOpen(false)} />}
    </>
  );
}
