import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  background-color: white;
  display: flex;
  width: 100%;
  height: 60%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  -ms-overflow-style: none;
  overflow-y: auto;

  h1 {
    margin: 5px 0;
    margin-top: -35px;
    font-size: 20px;
    font-family: 'MyFont';
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 0px;
    font-family: 'MyFont';
  }

  .Button {
    margin: 20px;
    margin-top: -5%;
    width: 100%; /* Ajuste a largura do botão para 100% da largura disponível */
    max-width: 428px; /* Define um valor máximo para o botão */
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    overflow-y: auto;

    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
    }
  }

  .button-custom {
    background-color: #ae1100;
    color: #fff;
    border: none;
  }

  .button-custom:hover {
    background-color: #ae1100;
  }

  .button-custom:disabled {
    background-color: #ae1100;
    color: #ae1100;
  }
  Button {
    margin: 20px;
    width: 100%; /* Ajuste a largura do botão para 100% da largura disponível */
    max-width: 428px; /* Define um valor máximo para o botão */
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    overflow-y: auto;

    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
    }
  }

  .button-custom {
    background-color: #ae1100;
    color: #fff;
    border: none;
  }

  .button-custom:hover {
    background-color: #ae1100;
  }

  .button-custom:disabled {
    background-color: #ae1100;
    color: #ae1100;
  }

  .text-field {
    font-family: 'MyFont';

    & label.Mui-focused {
      font-family: 'MyFont';
      color: #ae1100;
    }

    & .MuiOutlinedInput-root {
      font-family: 'MyFont';

      &.Mui-focused fieldset {
        border-color: #ae1100;
        .no-border {
          border: none;
        }
      }
    }
  }

  .fixed-height .MuiOutlinedInput-root {
    height: auto; /* Altura automática para ajustar ao conteúdo */
    border: none;
  }

  .fixed-height .MuiOutlinedInput-multiline {
    padding: 5px; /* Remover padding interno */
    border: none;
  }

  .fixed-height .MuiInputBase-inputMultiline {
    height: 100px; /* Altura fixa */
    border: none;
  }

  .form-control {
    margin: 50px 0;
    width: 100%; /* Ajuste a largura para 100% */
    max-width: 520px; /* Define um valor máximo */
    margin-right: 10%;
  }

  .form-control h1 {
    margin-right: 80%; /* Ajusta a margem direita */
  }

  input {
    width: 100%; /* Faz o input ocupar 100% da largura disponível */
    max-width: 380px; /* Define a largura máxima */
    height: 50px; /* Ajuste a altura */
    margin: 10px 0;
    font-size: 18px;
    font-family: 'MyFont';
    padding: 0 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }

  .box-container {
    border: 2px solid #ddd;
    padding: 0px 20px;
    width: 100%;
    max-width: 520px; /* Define um valor máximo para a largura */
    height: auto; /* Altura automática */
    margin-top: 20px;
    border-radius: 8px;
    margin-bottom: 0;
    position: relative;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 50%;
    background-color: white;
  }

  /* Responsividade para telas maiores */
  @media screen and (min-width: 868px) {
    margin-top: 20px;
    width: 580px;
  }

  /* Responsividade para telas menores */
  @media screen and (max-width: 867px) {
    margin-top: 10px;
    padding: 3%;
    height: 50%;
    width: calc(100% - 40px); /* Largura total menos a margem lateral */
  }

  @media screen and (max-width: 500px) {
    margin-top: 10px;
    padding: 3%;
    height: 60%;
    width: calc(100% - 40px); /* Largura total menos a margem lateral */
  }
  @media screen and (max-width: 375px) {
    margin-top: 10px;
    padding: 3%;
    height: 70%;
    width: calc(100% - 40px); /* Largura total menos a margem lateral */
  }

  .select {
    font-size: 25px;
  }

  .option-label {
    width: 100%;
    max-width: 520px; /* Define um valor máximo para a largura */
    margin-bottom: 20px;
    @media screen and (max-width: 867px) {
      width: 100%;
      margin-bottom: 25px; /* Ajusta a margem inferior */
      font-size: 15px;
    }
  }
`;
