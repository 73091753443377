import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { FaEdit } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';

export default function Course() {
  const id = useSelector((state) => state.auth.user.id);
  const [courses, setCourse] = useState([]);
  const [access, setAccess] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/course');
        const course = response.data;
        setCourse(course);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/usercourse');
        const courseAccess = response.data.filter(
          (UserCourse) => UserCourse.userId && UserCourse.userId === Number(id),
        );
        setAccess(courseAccess);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        <h1> Cursos Existentes</h1>
        <h4>Cursos disponíveis no sistema</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="p">Nome do Curso</TableCell>
              <TableCell align="center" className="p">
                Editar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses
              .filter((course) => access.some((ac) => ac.courseId === course.id && ac.hasAccess))
              .map((course) => (
                <TableRow key={course.id}>
                  <TableCell className="p">{course.name}</TableCell>
                  <TableCell align="center">
                    <Link to={`/empAccess/${course.id}/edit`}>
                      <FaEdit color="black" size={isMobile ? 10 : 16} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </CollaboratorContainer>
    </Container>
  );
}
