import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import { Prompt } from 'react-router-dom'; // Importar Prompt para controlar a navegação
import { toast } from 'react-toastify'; // Importar a função toast para mostrar notificações
import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer } from './styled';
import axios from '../../services/axios';
import UserPopup from '../../components/Popup';
import Loading from '../../components/Loading';
import 'react-toastify/dist/ReactToastify.css'; // Importar o estilo do Toastify

export default function Course({ match }) {
  const id = get(match, 'params.id', '');
  const [access, setAccess] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [accessWithUserNames, setAccessWithUserNames] = useState([]);
  const [updatedAccess, setUpdatedAccess] = useState({}); // Armazena alterações temporárias

  // Inicializa o Toastify
  useEffect(() => {
    toast.configure();
  }, []);

  // Sincronizar o acesso com os nomes de usuário
  useEffect(() => {
    if (!Array.isArray(users)) return;

    const newAccess = access.map((acesso) => {
      const foundUser = users.find((user) => user.id === acesso.userId);
      return { ...acesso, userName: foundUser ? foundUser.nome : '' };
    });
    setAccessWithUserNames(newAccess);
  }, [access, users]);

  // Função para carregar os dados do servidor (acessos)
  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await axios.get(`/usercourse`);
      const acesso = response.data.filter((UserCourse) => UserCourse.courseId && UserCourse.courseId === Number(id));
      setAccess(acesso);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  // Carregar dados de acesso ao curso
  useEffect(() => {
    fetchData();
  }, [id]);

  // Carregar usuários do departamento "empresa"
  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/rhusers');
        const empresaUsers = response.data.filter(
          (user) =>
            (user.departamento && user.departamento.toLowerCase() === 'empresa') ||
            user.departamento.toLowerCase() === 'empresa + premium',
        );
        setUsers(empresaUsers);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  // Função para alternar o valor de acesso (permitir/bloquear) no estado temporário
  const handleToggleAccess = (userCourseId, currentAccess) => {
    setUpdatedAccess((prev) => ({
      ...prev,
      [userCourseId]: !currentAccess, // Inverte o valor atual
    }));
  };

  // Função para salvar as alterações no backend
  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      const updatePromises = Object.entries(updatedAccess).map(([userCourseId, newAccess]) =>
        axios.put(`/usercourse/${userCourseId}`, { hasAccess: newAccess }),
      );
      await Promise.all(updatePromises);

      // Recarregar os dados após salvar as alterações
      fetchData();
      setUpdatedAccess({}); // Limpar o estado de alterações temporárias
      setIsLoading(false);

      // Mostrar o toast de sucesso
      toast.success('Alterações salvas com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Tempo em milissegundos
      });
    } catch (error) {
      console.error('Erro ao salvar alterações em bloco:', error);
      setIsLoading(false);
      toast.error('Erro ao salvar alterações.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        <h1>Permissões</h1>
        <h4>Selecione empresa para ter acesso ao cursos</h4>

        {/* Prompt para avisar quando há alterações não salvas */}
        <Prompt
          when={Object.keys(updatedAccess).length > 0} // Verifica se há alterações não salvas
          message="Você tem alterações não salvas. Deseja sair e perder as alterações?"
        />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuário</TableCell>
              <TableCell className="TableCell" style={{ paddingLeft: '480px' }}>
                Permitir / bloquear
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accessWithUserNames
              .filter((acesso) => acesso.userName) // Filtra os acessos que têm um userName
              .map((acesso) => {
                const currentAccess = updatedAccess[acesso.id] ?? acesso.hasAccess; // Usa o valor temporário se existir, caso contrário o original
                return (
                  <TableRow key={acesso.id}>
                    <TableCell className="user-cell">{acesso.userName}</TableCell>
                    <TableCell>
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={currentAccess} // Mostra o estado atual
                        onChange={() => handleToggleAccess(acesso.id, currentAccess)} // Alternar valor
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <div style={{ marginTop: '20px' }}>
          <Button variant="contained" color="primary" onClick={handleSaveChanges}>
            Salvar Alterações
          </Button>
          <Button
            className="button"
            variant="outlined"
            color="secondary"
            onClick={() => setIsPopupOpen(true)}
            style={{ marginLeft: '10px' }}
          >
            Criar novo acesso
          </Button>
        </div>

        {/* Popup para adicionar novo acesso */}
        {isPopupOpen && (
          <UserPopup
            userList={users.filter((user) => !access.some((acesso) => acesso.userId === user.id))}
            courseId={id}
            onClose={() => setIsPopupOpen(false)}
          />
        )}
      </CollaboratorContainer>
    </Container>
  );
}

Course.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
