import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import axios from '../../../services/axios';

import { StyledButton } from './styled';

export default function SubmoduleEdit({ match }) {
  const userId = useSelector((state) => state.auth.user.id);
  const courseId = get(match, 'params.id', '');

  useEffect(() => {
    const errorMessage = localStorage.getItem('errorMessage');
    if (errorMessage) {
      toast.error(errorMessage);
      localStorage.removeItem('errorMessage');
    }
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submodules] = useState([]);
  const [selectedAnswers] = useState({});
  const [passedSubmodules, setPassedSubmodules] = useState([]);

  const [quizPassed, setQuizPassed] = useState(false);
  const [finalMessage, setFinalMessage] = useState('');
  const [quizScore, setQuizScore] = useState(0);

  useEffect(() => {
    let interval;
    const storedTimeoutEndTime = localStorage.getItem('timeoutEndTime');
    if (storedTimeoutEndTime) {
      interval = setInterval(() => {
        const now = Date.now();
        const timeRemaining = Math.max(storedTimeoutEndTime - now, 0);
        setTimeoutRemaining(timeRemaining);
        if (timeRemaining === 0) {
          setIsInTimeout(false);
          localStorage.removeItem('timeoutEndTime');
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  function calculateCompletionPercentage(passedSubmodulesList, submodulesList) {
    const totalQuizzes = submodulesList.reduce((acc, submodule) => acc + submodule.quizzes.length, 0);
    const completedQuizzes = passedSubmodulesList.reduce((acc, passedSubmodule) => {
      const submodule = submodulesList.find((s) => s.id === passedSubmodule.submodule_id);
      return acc + (submodule ? submodule.quizzes.length : 0);
    }, 0);

    // Calcula a porcentagem e garante que não exceda 100%
    const percentage = (completedQuizzes / totalQuizzes) * 100;
    console.log('Percentage:', percentage);
    return Math.min(percentage, 100); // Garante que a porcentagem não exceda 100%
  }

  async function checkQuizAnswers(quiz) {
    const correctAnswersCount = Object.values(selectedAnswers).filter((answer) => answer.correct).length;
    const { maxScore } = quiz;
    const lastSubmoduleQuiz = submodules[submodules.length - 1].quizzes[0];
    const isLastQuiz = lastSubmoduleQuiz ? lastSubmoduleQuiz.id === quiz.id : false;
    if (isInTimeout) {
      toast.error(
        `Você não passou nesta avaliação. Revise o conteúdo e tente de novo em: ${Math.ceil(
          timeoutRemaining / 1000 / 60,
        )} minutos. . Boa sorte!`,
      );
      return;
    }

    setQuizScore(correctAnswersCount); // Atualiza a pontuação do quiz

    if (correctAnswersCount >= maxScore && isLastQuiz) {
      setOpenModal(true);
    }
    if (correctAnswersCount >= maxScore) {
      setQuizPassed(true);
      setOpenModal(true);
      if (isLastQuiz) {
        axios.put(`/rhusers/${userId}`, {
          graduacao: +1,
        });
        setFinalMessage('Parabéns por essa graduação! Você concluiu o treinamento e ganhara uma mudança de faixa!.');
      } else {
        setFinalMessage('Parabéns! Você passou no quiz!');
      }

      const idUserCourse = await axios.get('/usercourse');
      console.log('idUserCourse data:', idUserCourse.data);
      const mycourse = idUserCourse.data.find(
        (course) => String(course.userId) === String(userId) && String(course.courseId) === String(courseId),
      );
      console.log('mycourse:', mycourse);

      // Add the currently completed submodule to the list of completed submodules
      const newPassedSubmodules = [...passedSubmodules, { submodule_id: selectedSubmodule.id, passedQuiz: true }];

      // Calculate completion percentage based on the new list of completed submodules
      const percentage = calculateCompletionPercentage(newPassedSubmodules, submodules);
      console.log('Completion Percentage:', percentage);

      axios.put(`/usercourse/${mycourse.id}`, {
        completionPercentage: percentage,
      });

      axios
        .post('/usersubmodule', {
          userId,
          submodule_id: selectedSubmodule.id,
          quizScore: correctAnswersCount,
          passedQuiz: true,
        })
        .then(() => {
          setPassedSubmodules(newPassedSubmodules);
        });
    } else {
      setIsInTimeout(true);
      const newTimeoutEndTime = Date.now() + timeoutDuration;
      localStorage.setItem('timeoutEndTime', newTimeoutEndTime);
      // Verifica se o registro já existe
      try {
        const response = await axios.get('/usersubmodule');
        const filteredData = response.data.filter(
          (item) => item.userId === Number(userId) && item.submodule_id === Number(selectedSubmodule.id),
        );
        if (filteredData.length > 0) {
          // Atualiza o registro existente
          await axios.put(`/usersubmodule/${filteredData[0].id}`, {
            timeout: newTimeoutEndTime,
          });
        } else {
          // Cria um novo registro
          await axios.post('/usersubmodule', {
            userId,
            submodule_id: selectedSubmodule.id,
            timeout: newTimeoutEndTime,
          });
        }
      } catch (error) {
        console.error('Erro ao atualizar o timeout no banco de dados:', error);
      }
      const errorMessage = `Você acertou ${correctAnswersCount} perguntas. A pontuação necessária para passar é ${maxScore}.`;
      localStorage.setItem('errorMessage', errorMessage);
      window.location.reload();
    }
  }
  const handleCloseModal = () => {
    setOpenModal(false); // Modificação: Modal fecha apenas quando usuário clica no botão
    window.location.reload(); // Recarregar a página após fechar o modal
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%', // Ajuste a largura para ser proporcional
          maxWidth: '600px', // Largura máxima do modal
          backgroundColor: 'white',
          boxShadow: 24,
          padding: '16px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowY: 'auto', // Permite rolagem se o conteúdo for grande
        }}
      >
        <div style={{ flex: '1 0 auto', width: '100%' }}>
          <h2 id="modal-modal-title" style={{ textAlign: 'center' }}>
            {selectedQuiz ? selectedQuiz.title : 'Quiz'}
          </h2>
          {selectedQuiz && currentQuestionIndex < selectedQuiz.questions.length && (
            <>
              <p id="modal-modal-description">{selectedQuiz.questions[currentQuestionIndex].question}</p>
              <div />
              <button
                type="button"
                onClick={() => {
                  if (currentQuestionIndex + 1 === selectedQuiz.questions.length) {
                    checkQuizAnswers(selectedQuiz);
                  } else {
                    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                  }
                }}
                style={{
                  padding: '8px 16px',
                  backgroundColor: '#ae1100',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  transition: 'background-color 0.3s',
                  display: 'none',
                }}
              >
                {currentQuestionIndex + 1 === selectedQuiz.questions.length ? 'Finalizar Quiz' : 'Próxima Pergunta'}
              </button>
            </>
          )}
          {quizPassed && (
            <div>
              <h2
                style={{
                  fontSize: '25px', // Tamanho da fonte padrão para telas maiores
                }}
              >
                Avaliação Finalizada!
              </h2>
              <p
                style={{
                  fontSize: '16px', // Tamanho da fonte padrão para telas maiores
                }}
              >
                {finalMessage}
              </p>
              <p
                style={{
                  fontSize: '16px', // Tamanho da fonte padrão para telas maiores
                }}
              >
                Sua pontuação: {quizScore}
              </p>
            </div>
          )}
        </div>
        <StyledButton
          onClick={handleCloseModal}
          style={{
            alignSelf: 'flex-start',
            marginTop: '16px',
            textAlign: 'center',
            padding: '8px 16px',
            backgroundColor: '#ae1100',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontWeight: 'bold',
            transition: 'background-color 0.3s',
          }}
        >
          Fechar
        </StyledButton>
      </div>
    </Modal>
  );
}
SubmoduleEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
