import styled from 'styled-components';
import MyFont from '../../../fonts/eurosoft.ttf';

export const Container = styled.section`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom, #f8f8f8, #ffffff);
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;

  .question {
    font-family: 'MyFont';
    font-size: 1.2rem;
    font-weight: bold;
    color: #ae1100;
    margin-bottom: 20px;
    text-align: center;
  }

  .radio {
    margin-right: 10px;
    accent-color: #ae1100; /* Ajusta a cor do radio button */
  }

  label {
    font-family: 'MyFont';
    font-size: 1rem;
    color: #ae1100;
    cursor: pointer;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    max-width: 500px;
  }

  .button-quiz {
    font-family: 'MyFont';
    font-size: 1rem;
    background-color: #ae1100;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #8e0e00;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  p {
    font-family: 'MyFont';
    font-size: 1rem;
    color: #333;
    margin-top: 20px;
  }

  /* Ajustes responsivos */
  @media (max-width: 768px) {
    .question {
      font-size: 1rem;
    }

    .button-quiz {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }

  @media (max-width: 430px) {
    .question {
      font-size: 0.9rem;
    }

    .button-quiz {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
`;
