import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { CardContent, Typography, Divider } from '@material-ui/core';
import axios from '../../services/axios';
import { Container } from '../../styles/GlobalStyles';
import { DocumentViewerContainer, StyledCard } from './styled';
import 'react-toastify/dist/ReactToastify.css';

function DocumentViewer() {
  const [documents, setDocuments] = useState([]);
  const cnpjCreator = useSelector((state) => state.auth.user.cnpj_enterprise);

  const getDocuments = async () => {
    try {
      const response = await axios.get('/policy');
      const userDocuments = response.data.filter((doc) => doc.cnpj === cnpjCreator);
      console.log('Documentos filtrados:', userDocuments);

      setDocuments(userDocuments);
    } catch (error) {
      console.error('Erro ao buscar documentos:', error);
      toast.error('Erro ao buscar documentos.');
    }
  };

  useEffect(() => {
    getDocuments();
  }, [cnpjCreator]);

  const groupedByCategory = documents.reduce((acc, doc) => {
    if (!acc[doc.category]) {
      acc[doc.category] = [];
    }
    acc[doc.category].push(doc);
    return acc;
  }, {});

  // Ordem desejada para as categorias
  const categoryOrder = ['Políticas', 'Normas', 'Procedimentos'];

  // Ordenar categorias com base na ordem definida
  const orderedCategories = Object.keys(groupedByCategory).sort((a, b) => {
    const indexA = categoryOrder.indexOf(a);
    const indexB = categoryOrder.indexOf(b);
    return indexA - indexB;
  });

  return (
    <Container>
      <DocumentViewerContainer>
        <h2>Gestão de Documentos</h2>
        <h4>Clique no cartão para visualizar os documentos</h4>
        <div>
          {orderedCategories.length > 0 ? (
            orderedCategories.map((category) => (
              <div key={category}>
                <Typography
                  className="h5"
                  variant="h5"
                  style={{ marginTop: '20px', marginBottom: '10px', fontFamily: 'MyFont' }}
                >
                  {category}
                </Typography>

                <Divider style={{ marginBottom: '20px' }} />

                <div
                  style={{
                    display: 'flex',
                    gap: '5%',
                    flexWrap: 'wrap',
                  }}
                >
                  {groupedByCategory[category].map((doc) => (
                    <StyledCard key={doc.id} as="a" href={doc.link} target="_blank" rel="noopener noreferrer">
                      <CardContent>
                        <Typography
                          variant="h4"
                          component="h4"
                          style={{
                            display: 'flex',
                            textAlign: 'center',
                            fontFamily: 'MyFont',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          {doc.name || doc.link}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph>
                          <a href={doc.link} target="_blank" rel="noopener noreferrer">
                            {doc.link}
                          </a>
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p>Não há documentos disponíveis.</p>
          )}
        </div>
      </DocumentViewerContainer>
      <ToastContainer />
    </Container>
  );
}

export default DocumentViewer;
