import React from 'react';
// import { useSelector } from 'react-redux';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import PropTypes from 'prop-types';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
// import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
// import Background from '../../assets/img/background.png';

import { Container } from '../../styles/GlobalStyles';
// import { CollaboratorContainer, StyledCard } from './styled';
// import axios from '../../services/axios';

// import Loading from '../../components/Loading';

export default function Certificaiton() {
  // const id = useSelector((state) => state.auth.user.id);
  // const name = useSelector((state) => state.auth.user.nome);
  // const sobrenome = useSelector((state) => state.auth.user.sobrenome);
  // const [courses, setCourse] = useState([]);
  // const [access, setAccess] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const data = new Date().toLocaleDateString('pt-BR');
  // const [empresa, setEmpresa] = useState([]);
  // const filteredCourses = courses.filter((course) =>
  //   access.some((ac) => ac.courseId === course.id && ac.userId === id && ac.completionPercentage === 100),
  // );
  // useEffect(() => {
  //   async function getCourses() {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.get('/course');
  //       const course = response.data;
  //       setCourse(course);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //     }
  //   }

  //   getCourses();
  // }, []);

  // useEffect(() => {
  //   async function getCourses() {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.get(`/rhusers/${id}`);
  //       const users = response.data;

  //       setFilteredUser(users);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //     }
  //   }

  //   getCourses();
  // }, []);

  // useEffect(() => {
  //   async function getUsers() {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.get(`/rhusers/${id}`);
  //       const users = response.data;
  //       const responseEmpresa = await axios.get(`/rhusers/${users.creatorId}`);
  //       const empresas = responseEmpresa.data;
  //       setEmpresa(empresas);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //     }
  //   }

  //   getUsers();
  // }, []);

  // useEffect(() => {
  //   async function getAccess() {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.get('/usercourse');
  //       const courseAccess = response.data.filter(
  //         (UserCourse) =>
  //           UserCourse.userId && UserCourse.userId === Number(id) && UserCourse.completionPercentage === 100,
  //       );
  //       setAccess(courseAccess);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //     }
  //   }

  //   getAccess();
  // }, [id]);

  return (
    <Container
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Alinha o conteúdo verticalmente no centro
        height: '100vh',
        paddingTop: '50px',
        paddingBottom: '50px', // Adiciona espaço nas partes superior e inferior
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '960px', // Limita a largura máxima
          height: '100%',
          paddingBottom: '56.25%', // Proporção 16:9
          overflow: 'hidden',
          margin: '0 20px', // Margem lateral
        }}
      >
        <iframe
          style={{
            position: 'absolute',
            top: '15%',
            left: '0',
            width: '100%',
            height: '50%',
          }}
          src="https://www.youtube.com/embed/EduEuh0gJgI?start=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Container>
  );
}
