import styled from 'styled-components';

export const WelcomeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%; /* Espaçamento lateral */
  }

  .sensei-box {
    margin-top: 2%;
    width: 50%;
    height: 80%;

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  text-align: center; /* Centraliza o texto */

  h1,
  h2 {
    margin: 20px 0; /* Ajusta a margem superior/inferior */
    font-size: 60px;

    @media (max-width: 1024px) {
      font-size: 40px; /* Tamanho do texto ajustado */
    }
    @media (max-width: 430px) {
      font-size: 25px;
    }
  }

  p {
    margin: 10px 0;
    font-weight: bold;
    font-size: 35px;
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 25px;
    }
    @media (max-width: 566px) {
      font-size: 20px;
    }
  }

  .button-welcome {
    margin-top: 20px;
    font-size: 15px;
    width: 60%;
    color: white;
    text-transform: none;
    font-weight: bold;
    font-family: 'MyFont';
    background-color: #ae1100;
    text-align: center;

    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
    }

    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 500px) {
      width: 90%;
      font-size: 12px;
    }
    @media (max-width: 320px) {
      width: 95%;
      font-size: 10px;
    }
  }
  .logo-box {
    width: 60%;
    margin-bottom: -15%;
    height: auto; /* Garante proporção da imagem */

    @media (max-width: 1024px) {
      width: 70%; /* Ajusta a largura para telas menores */
      margin-bottom: -15%;
      margin-top: -15%;
    }

    @media (max-width: 574px) {
      width: 100%; /* Ajusta largura para telas muito pequenas */
      margin-bottom: -25%;
    }
  }
`;

export const ParagraphContent = styled.div`
  display: flex;
  flex-direction: row; /* Alinha os itens horizontalmente */
  margin-top: 10%;
  align-items: center; /* Centraliza o conteúdo verticalmente */
  justify-content: center; /* Centraliza os itens horizontalmente */
  padding: 2%;

  h1 {
    font-size: 35px;
    text-align: justify;
    margin-bottom: 10px; /* Espaço entre o h1 e o parágrafo */

    @media (max-width: 1024px) {
      font-size: 22px;
      text-align: center; /* Alinha no centro em telas menores */
      margin-bottom: 15px;
    }
    @media (max-width: 1986px) {
      font-size: 25px;
      text-align: start;
      margin-bottom: 15px;
    }
    @media (max-width: 1018px) {
      font-size: 25px;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  p {
    margin-top: 10px;
    font-size: 25px;
    max-width: 40%;
    margin-right: 20%;
    text-align: justify;

    @media (max-width: 1024px) {
      font-size: 17px;
      max-width: 100%; /* Ajusta a largura para telas menores */
      text-align: center; /* Centraliza o texto */
      margin-right: 0%;
      margin-top: 0px;
    }
    @media (max-width: 1928px) {
      font-size: 17px;
    }
  }

  .button-welcome {
    margin-top: 20px;
    font-size: 15px;
    width: 60%;

    color: white;
    text-transform: none;
    font-weight: bold;
    font-family: 'MyFont';
    background-color: #ae1100;
    text-align: center;

    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
    }

    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 500px) {
      width: 90%;
      font-size: 12px;
    }
    @media (max-width: 320px) {
      width: 95%;
      font-size: 10px;
    }
    @media (max-width: 1625px) {
      width: 60%;
      font-size: 10px;
      height: 30px;
    }
    @media (max-width: 1065px) {
      width: 60%;
      font-size: 10px;
    }
  }

  .logo-box {
    margin-top: -15%;
    width: 60%; /* A logo ocupa 30% da largura */
    height: auto; /* Garante proporção da imagem */
    margin-bottom: -2%;

    @media (max-width: 1024px) {
      width: 40%; /* Ajusta a largura para telas menores */
      margin-top: 0%;
      margin-bottom: -5%;
    }

    @media (max-width: 574px) {
      width: 60%; /* Ajusta para telas muito pequenas */
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column; /* Muda a direção para coluna em telas menores */
    align-items: center; /* Centraliza verticalmente */
    justify-content: center; /* Centraliza horizontalmente */
  }
`;
