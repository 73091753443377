import { Button } from '@material-ui/core'; // Importação de pacotes externos deve vir primeiro
import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 95vw; /* Ajuste a largura para adaptação em telas menores */
  max-width: 750px; /* Limita a largura máxima em telas grandes */
  height: auto; /* Permite que a altura seja ajustada ao conteúdo */
  max-height: 85vh; /* Limita a altura máxima */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  overflow-y: auto; /* Permite rolagem apenas vertical */
  overflow-x: hidden; /* Impede rolagem horizontal */
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Estilos para dispositivos móveis */
  @media (max-width: 768px) {
    width: 90vw; /* Reduz a largura em tablets */
    max-width: 90%; /* Mais flexível em telas menores */
    height: auto;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 95vw; /* Quase ocupa toda a largura da tela */
    padding: 0px;
    border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
  }
`;

export const Overlay = styled.div`
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Form = styled.form`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Impede rolagem horizontal */
  }
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  font-size: 20px;
  max-width: 580px;
  margin: -5% auto 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto; /* Permite rolagem apenas vertical */
  overflow-x: hidden; /* Impede rolagem horizontal */

  .invalid-label {
    color: red;
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin: 5px;
    margin-left: 15px;
    font-family: 'MyFont';
    font-size: 15px;
  }

  h1 {
    margin: -32px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 820px) {
      width: 27vw; /* Reduz a largura em tablets */
      max-width: 90%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 30px;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 40vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 25px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 40vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 25px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }

  h4 {
    margin: 15px 1px;
    font-size: 18px;
    margin-bottom: 2%;
    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  .button-eye {
    width: 100%;
    @media (max-width: 768px) {
      width: 150%;
      height: 150%; /* Reduz a largura em tablets */
    }

    @media (max-width: 480px) {
      width: 150%; /* Quase ocupa toda a largura da tela */
      height: 150%;
    }
  }
  .button-eye svg {
    width: 24px; /* Tamanho padrão */
    height: 24px; /* Tamanho padrão */

    @media (max-width: 1024px) {
      width: 20px; /* Reduzir tamanho em telas menores */
      height: 20px;
    }

    @media (max-width: 768px) {
      width: 18px; /* Reduzir ainda mais para dispositivos pequenos */
      height: 18px;
    }

    @media (max-width: 480px) {
      width: 16px; /* Tamanho mínimo para dispositivos muito pequenos */
      height: 16px;
    }
  }

  .password-checklist {
    font-family: 'MyFont';
    border-color: #ae1100;
    label {
      font-family: 'MyFont';
      display: flex;
      border-color: #ae1100;
      font-size: 15px;
      flex-direction: column;
      margin-bottom: 20px;
      margin-left: 10px;
    }
    @media (max-width: 768px) {
      width: 80vw; /* Reduz a largura em tablets */
      max-width: 90%; /* Mais flexível em telas menores */
      height: auto;
      padding: 0px;
    }

    @media (max-width: 480px) {
      width: 70vw; /* Quase ocupa toda a largura da tela */
      padding: 0px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  input,
  select {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0 10px;
    font-family: 'MyFont';
    border-radius: 4px;
    margin: 2px 0px 10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  select {
    width: 100%; /* Modificado para ocupar toda a largura disponível */
    margin-top: 5px;
    font-family: 'MyFont';
    font-size: 15px;
  }

  @media (max-width: 768px) {
    input,
    select {
      width: 80%;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    input,
    select {
      width: 100%;
    }
  }
  .text-field {
    font-size: 15px;
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
    @media (max-width: 768px) {
      width: 80vw; /* Reduz a largura em tablets */
      max-width: 120%; /* Mais flexível em telas menores */
      height: auto;
      padding: 0px;
    }

    @media (max-width: 480px) {
      width: 70vw; /* Quase ocupa toda a largura da tela */
      padding: 0px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 90px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
    /* Estilos para dispositivos móveis */
    @media (max-width: 768px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      margin-top: 10%;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 80vw; /* Quase ocupa toda a largura da tela */
      border-radius: 10px;
      margin-top: 20%; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 85vw; /* Quase ocupa toda a largura da tela */
      padding: 20px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 75%;
    background-color: white;
  }
  .autocomplete {
    font-family: 'MyFont', sans-serif !important;
    @media (max-width: 768px) {
      width: 454px !important; /* Reduz a largura em tablets */
      height: auto;
      padding: 0px;
    }

    @media (max-width: 480px) {
      width: 354px !important; /* Quase ocupa toda a largura da tela */
      padding: 0px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      margin-left: 15px;
      margin-top: 3.2%;
      font-family: 'MyFont' !important;
      font-size: 15px; /* Adicionado tamanho da fonte */
    }
    .MuiAutocomplete-popupIndicator {
      position: absolute;
      font-family: 'MyFont', sans-serif !important;
      right: 65px; /* ajuste este valor conforme necessário */
    }
    .MuiInput-underline:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }
    .MuiInput-underline:after {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }
    .MuiInput-underline:hover:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }

    .autocomplete .MuiInput-underline:before,
    .autocomplete .MuiInput-underline:after,
    .autocomplete .MuiInput-underline:hover:before {
      font-family: 'MyFont', sans-serif !important;
      border-bottom: none;
    }

    .autocomplete button,
    .autocomplete button:hover {
      font-family: 'MyFont', sans-serif !important;
    }

    .autocomplete .MuiAutocomplete-option {
      font-family: 'MyFont', sans-serif !important;
      color: black; /* ajuste a cor do texto conforme necessário */
      background-color: white; /* ajuste a cor de fundo conforme necessário */
    }

    .autocomplete .MuiAutocomplete-option:hover {
      font-family: 'MyFont', sans-serif !important;
      background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    }

    .autocomplete input {
      margin: initial;
      padding: 20px;
      font-family: 'MyFont', sans-serif !important;
      margin-top: 3%;
      @media (max-width: 768px) {
        width: 90vw !important; /* Reduz a largura em tablets */
        max-width: 120%; /* Mais flexível em telas menores */
        padding: 0px;
      }

      @media (max-width: 480px) {
        width: 90vw !important; /* Quase ocupa toda a largura da tela */
        padding: 0px;
      }
    }

    input {
      margin: initial;
      padding: 20px;
      font-family: 'MyFont', sans-serif !important;
      margin-top: 3%;
      @media (max-width: 768px) {
        width: 100vw !important; /* Reduz a largura em tablets */
        padding: 0px;
      }

      @media (max-width: 480px) {
        width: 100vw !important; /* Quase ocupa toda a largura da tela */
        padding: 0px;
      }
    }

    button {
      font-family: 'MyFont', sans-serif !important;
      color: initial;
      display: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    button:hover {
      font-family: 'MyFont', sans-serif !important;
      color: initial;
      display: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      max-height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    .MuiAutocomplete-option {
      font-family: 'MyFont', sans-serif !important;
      color: black; /* ajuste a cor do texto conforme necessário */
      background-color: white; /* ajuste a cor de fundo conforme necessário */
    }
    .MuiAutocomplete-option:hover {
      font-family: 'MyFont', sans-serif !important;
      background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    }
  }
  @media (max-width: 768px) {
    form {
      padding: 8px;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 10px !important;
  margin-top: 20px !important;
  width: 250px !important;
  color: white !important;
  font-weight: bold !important;
  background-color: #ae1100 !important;
  font-family: 'MyFont' !important;
  z-index: 1000 !important;
  text-transform: none !important;
  @media (max-width: 768px) {
    font-size: 10px !important;
  }

  @media (max-width: 480px) {
    font-size: 10px !important;
  }
  &:hover {
    background-color: #ae1100 !important;
    transition-duration: 0s !important;
  }
`;
