import styled from 'styled-components';

export const DocumentViewerContainer = styled.div`
  max-width: 90%;
  margin: 5% auto;
  padding: 20px;
  margin-top: 10%;
  font-family: 'MyFont', sans-serif;

  /* Responsividade para telas menores */
  @media (max-width: 768px) {
    div {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-start;
      width: 100%;
    }

    h2 {
      font-size: 2.5rem; /* Tamanho reduzido para telas menores */
    }
    h3 {
      font-size: 1.2rem; /* Tamanho ajustado */
    }
    h4 {
      font-size: 1rem; /* Tamanho ajustado */
    }
  }

  @media (max-width: 550px) {
    div {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      width: 100%;
      padding: 0 10px;
    }
    h2 {
      font-size: 1.6rem; /* Fontes ainda menores */
    }
    h3 {
      font-size: 1rem; /* Ajuste adicional */
    }
    h4 {
      font-size: 0.875rem; /* Ajuste para h4 */
    }
  }

  @media (max-width: 460px) {
    div {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      width: 100%;
      padding: 0 10px;
    }
    h2 {
      font-size: 1.4rem; /* Fontes menores para telas pequenas */
    }
    h3 {
      font-size: 0.875rem; /* Ajuste adicional */
    }
    h4 {
      font-size: 0.75rem; /* Ajuste para h4 */
    }
  }

  h2 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  h4 {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 15px;
    color: #333;
  }

  h3 {
    font-size: 1.5rem;
    margin: 15px 0;
    text-align: center;
    color: #555;
  }

  ul {
    font-family: 'MyFont';
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    font-family: 'MyFont';
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 10px;
    margin: 5px 0;
    border-radius: 8px;

    a {
      color: #007bff;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .h5 {
    font-size: 24px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    margin-top: 25%;

    h4 {
      font-size: 2.5rem;
    }
    .h5 {
      font-size: 3rem;
    }

    h2 {
      font-size: 4.2rem;
    }

    h3 {
      font-size: 1rem;
    }

    li {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px;
    }
  }
`;

export const StyledCard = styled.div`
  font-family: 'MyFont';
  width: 20%;
  height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  margin: 0;
  flex-direction: column;
  margin-bottom: 5%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(174, 17, 0, 0.5);
  }

  h3 {
    font-size: 1rem;
    text-align: center;
    color: #333;
    margin: 10px 0;
  }

  p {
    font-size: 0.875rem;
    color: #555;
    text-align: center;
    margin: 10px 0;
  }

  .button {
    padding: 8px 12px;
    width: 100%;
    color: white;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
    background-color: #ae1100;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: auto;
  }

  @media (max-width: 550px) {
    h3 {
      font-size: 0.875rem; /* Ajuste para telas menores */
    }

    p {
      font-size: 0.75rem; /* Ajuste para o texto dos cards */
    }
  }

  @media (max-width: 460px) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    .button {
      font-size: 0.875rem;
      padding: 6px 10px;
    }
  }
`;
