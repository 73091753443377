import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MyFont from '../../fonts/eurosoft.ttf';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'MyFont';
  height: 100vh;
`;

export const CollaboratorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  width: 80%;
  max-width: 900px;
  padding: 30px 20px;
  border-radius: 8px;
  h1 {
    margin-top: 10%;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 30px;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 25px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 20px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }
  h4 {
    text-align: start; /* Mantém o título centralizado */
    font-family: 'MyFont';
    margin-bottom: 2%;
    @media (max-width: 1024px) {
      width: 100vw; /* Reduz a largura em tablets */
      max-width: 100%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 22px;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 15px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
    @media (max-width: 540px) {
      width: 100vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 15px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
    }
  }
  .p {
    font-family: 'MyFont';
    color: black;
    @media (max-width: 1024px) {
      width: 200vw; /* Reduz a largura em tablets */
      max-width: 90%; /* Mais flexível em telas menores */
      height: auto;
      font-size: 20px;
      padding: 15px;
      text-decoration: none;
    }

    @media (max-width: 540px) {
      width: 350vw; /* Quase ocupa toda a largura da tela */
      padding: 10px;
      font-size: 12px;
      border-radius: 10px; /* Reduz a borda arredondada em telas pequenas */
      text-decoration: none;
    }
  }

  margin-top: 4%;
  margin: 50px;
  div {
    display: flex;
    justify-content: space-between;
    padding: 5px 300px;
    margin-left: -30%;
  }

  div + div {
    border-top: 1px solid #eee;
    padding: 20px 300px;
    margin-left: -30%;
  }
  table {
    width: 100%;
    margin: 0 auto; /* Centraliza a tabela horizontalmente */
    border-collapse: collapse;

    th,
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-family: 'MyFont';
    }

    tr:hover {
      background-color: #f5f5f5;
    }

    .edit-cell {
      display: flex;
      align-items: center;
    }
  }
`;

export const NewCollaborator = styled(Link)`
  display: block;
`;

export const CenteredText = styled.h1`
  margin-top: 5%;
  margin-bottom: 50px;
  font-family: 'MyFont';
`;

export const NoHoverButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  align-items: center;
  gap: 5px; /* Espaço entre o ícone e o texto "Editar" */
`;
