import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Composition = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centraliza o conteúdo verticalmente */
  width: 100%;
  height: 100vh;
  .invalid-label {
    color: red;
  }
  .text-field {
    width: 450%;
    margin: 6%;
    max-width: 450px; /* Limita a largura máxima */
    font-family: 'MyFont';
    label {
      font-family: 'MyFont';
      display: flex;
      border-color: #ae1100;
      font-size: 15px;
      flex-direction: column;
      margin-bottom: 20px;
      margin-left: 10px;
    }
  }

  @media (max-width: 769px) {
    width: 90%;
    margin-top: -15%;
  }
  @media (max-width: 761px) {
    width: 90%;
    margin-top: -15%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
  @media (max-width: 375px) {
    width: 90%;
    height: 80%;
    margin-top: 5%;
  }
  .button-container {
    width: 150px;
    color: white;
    font-family: 'MyFont';
    font-weight: bold;
    background-color: #ae1100;
    margin: 10px; /* Adiciona margem ao redor do botão */
    margin-top: 20px; /* Mantém o espaçamento superior */
    transition: all 0.3s ease;

    @media (max-width: 1024px) {
      width: 200px;
      font-size: 14px;
    }

    @media (max-width: 768px) {
      width: 200px;
      font-size: 12px;
      padding: 3%;
    }

    @media (max-width: 480px) {
      width: 140px;
      font-size: 10px;
    }
    @media (max-width: 361px) {
      width: 110px;
      font-size: 10px;
    }
  }

  h2 {
    margin: 10px 30%;
    margin-top: 0%;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: -3%;
    font-family: 'MyFont';
    align-items: start;
  }

  h4 {
    margin: 15px 1px;
    font-size: 18px;
    margin-bottom: 2%;
  }

  .password-checklist {
    font-family: 'MyFont';
    border-color: #ae1100;
    label {
      font-family: 'MyFont';
      display: flex;
      border-color: #ae1100;
      font-size: 15px;
      flex-direction: column;
      margin-bottom: 20px;
      margin-left: 10px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 90px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
  }
  .logo {
    width: 20%; /* Define a largura como 100% do container */
    height: auto; /* Ajusta automaticamente a altura para manter a proporção */
    object-fit: contain; /* Garante que a imagem seja contida no espaço sem distorção */
    margin-bottom: -6%;

    @media (max-width: 768px) {
      width: 70%; /* Reduz a largura máxima em telas menores */
      margin-bottom: -20%;
    }

    @media (max-width: 480px) {
      width: 80%; /* Reduz ainda mais a largura máxima */
    }
    @media (max-width: 375px) {
      display: none;
    }
  }

  h1 {
    margin: 10px 0; /* Espaçamento superior e inferior */
    font-size: 25px; /* Tamanho inicial para telas grandes */
    font-family: 'MyFont';
    text-align: center;
    margin-bottom: 1.5%;

    @media (max-width: 1024px) {
      font-size: 26px; /* Reduz o tamanho para telas médias */
    }

    @media (max-width: 768px) {
      font-size: 22px; /* Reduz ainda mais para tablets */
    }

    @media (max-width: 480px) {
      font-size: 18px; /* Tamanho menor para dispositivos móveis */
    }
  }

  .text-field {
    width: 100%;
    height: 50px; /* Defina a altura do TextField */
    font-size: 15px; /* Tamanho da fonte dentro do input */
    padding: 0 10px;
    border: 1px solid #ae1100;
    border-radius: 4px;
    font-family: 'MyFont';
    box-sizing: border-box;
    display: block;
    margin: 0 auto 15px; /* Garantir que a margem inferior seja suficiente */
    position: relative; /* Permitir o uso de posicionamento interno */
  }

  /* Garantir que a label do TextField fique centralizada verticalmente */
  .text-field .MuiInputLabel-root {
    position: absolute;
    font-size: 18px; /* Mantém o tamanho da fonte da label */
    top: 50%; /* Coloca a label no centro vertical */
    transform: translateY(-50%); /* Ajusta para centralizar exatamente */
    font-family: 'MyFont'; /* Alinha a fonte com o resto do campo */
    color: #ae1100; /* Cor da label */
    transition: all 0.2s ease-in-out; /* Transição suave quando a label for movida */
  }

  /* Quando o TextField for focado ou tiver valor, a label vai para o topo */
  .text-field .MuiInputLabel-root.MuiInputLabel-shrink {
    top: -12px; /* Move a label para cima */
    transform: translateY(0); /* Remove o deslocamento vertical */
    font-size: 12px; /* Ajusta o tamanho da fonte quando a label encolher */
  }

  /* Ajustes para o TextField com valor ou foco */
  .text-field input:focus + .MuiInputLabel-root,
  .text-field input:not(:placeholder-shown) + .MuiInputLabel-root {
    top: -12px; /* Coloca a label sobre o campo */
    font-size: 12px; /* Reduz o tamanho da fonte da label */
    transform: translateY(0); /* Remove o deslocamento vertical */
  }

  /* Garantir que o texto dentro do input esteja centralizado verticalmente */
  .text-field input {
    height: 100%; /* Garante que o input tenha a altura completa do TextField */
    font-size: 15px; /* Define o tamanho da fonte dentro do input */
    padding: 0; /* Remove o padding interno, para centralizar o texto */
    display: flex;
    align-items: center; /* Centraliza o texto verticalmente */
    line-height: 1.5; /* Ajusta a altura da linha para centralização */
  }

  /* Garantir que o Autocomplete tenha borda correta */
  .autocomplete .MuiAutocomplete-root {
    width: 100%;
    margin: 0 auto;
    max-width: 550px;
    box-sizing: border-box;
    border: none !important; /* Removendo qualquer borda */
    position: absolute;
    font-size: 24px;
    font-family: 'MyFont', sans-serif !important;
    right: 65px; /* ajuste este valor conforme necessário */
  }
  .MuiInput-underline:before {
    font-family: 'MyFont', sans-serif !important;
    border-bottom: none;
    font-size: 24px;
  }
  .MuiInput-underline:after {
    font-family: 'MyFont', sans-serif !important;
    border-bottom: none;
    font-size: 24px;
  }
  .MuiInput-underline:hover:before {
    font-family: 'MyFont', sans-serif !important;
    border-bottom: none;
    font-size: 24px;
  }

  .autocomplete .MuiInput-underline:before,
  .autocomplete .MuiInput-underline:after,
  .autocomplete .MuiInput-underline:hover:before {
    font-family: 'MyFont', sans-serif !important;
    border-bottom: none;
    font-size: 24px;
  }

  .autocomplete button,
  .autocomplete button:hover {
    font-family: 'MyFont', sans-serif !important;
    font-size: 34px;
  }

  .autocomplete .MuiAutocomplete-option {
    font-family: 'MyFont', sans-serif !important;
    color: black !important;
    background-color: white !important;
    font-size: 34px;
  }

  .autocomplete .MuiAutocomplete-option:hover {
    font-family: 'MyFont', sans-serif !important;
    background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    font-size: 34px;
  }

  /* Remover a linha de foco do input dentro do Autocomplete */
  .autocomplete .MuiInputBase-root {
    border: none !important; /* Removendo a borda do input */
    outline: none !important; /* Removendo o outline */
    box-shadow: none !important; /* Removendo qualquer sombra que apareça */
    font-family: 'MyFont';
    font-size: 18px;
    margin: 0 auto 15px;
    padding: 0 10px;
    font-size: 34px;
  }
  @media (max-width: 890px) {
    .autocomplete .MuiAutocomplete-option {
      font-size: 22px !important; /* Aumenta o tamanho da fonte das opções */
    }
  }

  /* Responsividade para outras larguras específicas */
  @media (max-width: 768px) {
    .autocomplete .MuiAutocomplete-option {
      font-size: 24px !important; /* Aumenta ainda mais o tamanho da fonte em telas muito pequenas */
    }
  }

  /* Remover a linha azul de foco */
  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  /* Estilo das opções do Autocomplete */
  .custom-option {
    font-family: 'MyFont', sans-serif !important;
    color: black !important;
    background-color: white !important;
    font-size: 34px !important;
  }

  .custom-option:hover {
    background-color: #f5f5f5;
  }

  /* Responsividade */
  @media (max-width: 768px) {
    .text-field,
    .autocomplete {
      width: 80%;
    }
  }

  @media (max-width: 480px) {
    .text-field,
    .autocomplete {
      width: 90%;
    }
  }

  @media (max-width: 912px) {
    .autocomplete {
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    .autocomplete {
      width: 100%;
    }
  }
  @media (max-width: 2560px) {
    .autocomplete {
      width: 100%;
    }
  }

  .terms-container {
    display: flex;
    align-items: center; /* Alinha o checkbox e o texto verticalmente */
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
    font-family: 'MyFont';
    font-size: 15px;
    margin-top: 10px;
    text-align: center; /* Garante que o texto seja centralizado */
  }

  .terms-container a {
    color: #ae1100;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    margin-left: 5px; /* Ajuste o espaço entre o texto e o link */
  }

  .terms-container a:hover {
    text-decoration: underline;
  }

  /* Ajustes para o Checkbox */
  .terms-container .MuiCheckbox-root {
    color: #ae1100;
    margin-left: 20px; /* Ajuste a posição do checkbox para não ficar muito distante */
    margin-top: -10%;

    @media (max-width: 480px) {
      width: 40px;
    }
  }

  /* Responsividade */
  @media (max-width: 768px) {
    .terms-container {
      justify-content: flex-start; /* Alinha ao início da tela em telas menores */
    }

    .terms-container a {
      font-size: 14px; /* Ajusta o tamanho da fonte em telas pequenas */
    }
  }

  /* Ajuste em telas muito pequenas */
  @media (max-width: 480px) {
    .terms-container {
      font-size: 14px;
      justify-content: flex-start; /* Garante que o checkbox e o link fiquem juntos */
    }

    .terms-container a {
      font-size: 13px; /* Reduz um pouco o tamanho da fonte */
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10%;
  justify-content: center;

  .logo {
    width: 100%; /* Define a largura como 100% do container */
    height: auto; /* Ajusta automaticamente a altura para manter a proporção */
    margin-bottom: -30%; /* Ajusta a margem inferior */
    object-fit: contain; /* Garante que a imagem seja contida no espaço sem distorção */

    @media (max-width: 768px) {
      max-width: 90%; /* Reduz a largura máxima em telas menores */
    }

    @media (max-width: 480px) {
      max-width: 90%; /* Reduz ainda mais a largura máxima */
    }
  }

  h1 {
    margin: 10px 0; /* Espaçamento superior e inferior */
    font-size: 30px; /* Tamanho inicial para telas grandes */
    font-family: 'MyFont';
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 26px; /* Reduz o tamanho para telas médias */
    }

    @media (max-width: 768px) {
      font-size: 22px; /* Reduz ainda mais para tablets */
    }

    @media (max-width: 480px) {
      font-size: 18px; /* Tamanho menor para dispositivos móveis */
    }
  }

  .text-field {
    width: 100%;
    max-width: 450px; /* Limita a largura máxima */
    font-family: 'MyFont';
  }
  input {
    width: 100%;
    height: 50px;
    font-size: 18px;
    padding: 0 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }

  button {
    width: 150px;
    color: white;
    font-family: 'MyFont';
    font-weight: bold;
    background-color: #ae1100;
    margin: 10px; /* Adiciona margem ao redor do botão */
    margin-top: 20px; /* Mantém o espaçamento superior */
    transition: all 0.3s ease;

    @media (max-width: 1024px) {
      width: 200px;
      font-size: 14px;
    }

    @media (max-width: 768px) {
      width: 200px;
      font-size: 12px;
      padding: 3%;
    }

    @media (max-width: 480px) {
      width: 140px;
      font-size: 10px;
    }
    @media (max-width: 361px) {
      width: 110px;
      font-size: 10px;
    }
  }
`;

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff; /* Fundo branco */
  display: flex;
  flex-direction: column; /* Garante alinhamento central em coluna */
  justify-content: center; /* Alinha verticalmente */
  align-items: center; /* Alinha horizontalmente */
  overflow: hidden;
  overflow-y: auto;
  z-index: 0; /* Mantém abaixo de Nav e SecondNav */
`;
